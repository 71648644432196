import React, {useState, useEffect, createContext} from 'react'
import Axios from 'axios';
import proxy from '../proxy';

export const DataContext = createContext([]);

const DataProvider = (props) => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        Axios.get(`${proxy}/server/`).then((response) => {
          setProducts(response.data)
        })
      }, [setProducts]);

  return (
    <DataContext.Provider value={[products]}>
        {props.children}
    </DataContext.Provider>
  )
}

export default DataProvider