import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationItems = ({ pages, handleChangePage, page }) => {

  return (
    <Stack spacing={2} sx={{ mt: 1, display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Pagination color="primary" count={pages} variant="outlined"  shape="rounded" 
                  sx={{backgroundColor: "#FFFDFA", padding: "2px", borderRadius: "5px"}}
                  page={page} onChange={handleChangePage}
       />
  </Stack>
  )
}

export default PaginationItems