import React from 'react';
import classes from "./Footer.module.css";
import "../../index.css";
import { FiPhone, FiMail } from 'react-icons/fi';
import ANPC from '../../assets/pictogramaSOL.png'
import PIC_ANPC from '../../assets/SAL-PICTOGRAMA.png'
import Netopia from '../../assets/netopia.jpg'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={classes.footer}>
        {/* <h4 style={{fontFamily: "Springtime"}}>&copy; 2022 | Din pasiune pentru licheni</h4> */}
        <div className={classes.container}>
          <div className={classes.details}>
            <div>
              <h3>ABILITY LOGICAL AND MORE SOLUTIONS SRL</h3>
              <p>CUI: RO45425885</p>
              <p>J40/67/2022</p>
            </div>
            <div className={classes.anpc}>
              <a href='https://anpc.ro/ce-este-sal/' target="_blank" rel="noreferrer">
                <img src={ANPC} alt="anpc"/>
              </a>
                <a href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO' target="_blank" rel="noreferrer">
              <img src={PIC_ANPC} alt="anpc"/>
              </a>
            </div>
          </div>
          <div>
            <h3>Informații</h3>
            <p><Link to={`${process.env.PUBLIC_URL}/termeni-si-conditii`}>Termeni și condiții</Link></p>
            <p><Link to={`${process.env.PUBLIC_URL}/informatii-livrare`}>Informații livrare</Link></p>
            <p><Link to={`${process.env.PUBLIC_URL}/politica-retur`}>Politica de retur</Link></p>
          </div>
          <div>
            <h3>Contact</h3>
            <a href='mailto:office@almsolutions.ro' className={classes.contact}><FiMail /> office@almsolutions.ro</a>
            <a href='tel:0724142165' className={classes.contact}><FiPhone /> +40724 142 165</a>
          </div>
        </div>
        <img className={classes.footer_img} src={Netopia} alt="footer img" />
        <p className={classes.scaled}>&copy;Copyright 2023 lizi-art.ro | Developed by <a href="https://dan-angelin.com/" style={{color: "blue", fontWeight: 600}} target="_blank" rel="noreferrer">Angelin</a></p>
        </div>
  )
}

export default Footer