import React from 'react';
import classes from "./HomeImage.module.css"

const HomeImage = () => {
  return (
    <div className={classes.homeimg}>
        <div className={classes.imgtext}>
        <h1>Din pasiune pentru licheni</h1>
        <p>Spațiul verde din casa ta</p>
        </div>
    </div>
  )
}

export default HomeImage