import React from 'react';
import classes from "./Page.module.css";
import Header from "../Header/Header";
import Footer from '../Footer/Footer';

const Page = (props) => {
  return (
    <div className={classes.page}>
        <Header />
        {props.children}
        <Footer />
    </div>
  )
}

export default Page;