import React from 'react';
import Page from '../UI/Page';
import HomeImage from '../HomeImage/HomeImage';
import Products from '../Products/Products';
import CookieConsent from "react-cookie-consent";

const Home = () => {
  return (
    <Page>
      <CookieConsent buttonText="Accept" 
      style={{ fontSize: "15px" }} 
      buttonStyle={{ padding: "5px 15px", backgroundColor: "#E6E6E6", color: "#4e503b", fontSize: "15px", fontWeight: 600, borderRadius: "10px" }}>
      Folosim cookie-uri pentru a-ți oferi cea mai bună experiență pe site-ul nostru web.
      </CookieConsent>
        <HomeImage />
        <Products />
    </Page>
  )
}

export default Home