import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import Input from '@mui/joy/Input';
import Axios from 'axios';
import proxy from '../../../proxy';

const AddCategory = ({ getCategories }) => {

  const [inputCategory, setInputCategory] = useState('');
  const [inputOrdering, setInputOrdering] = useState(1);


  const handleInputCategory = (e) => {
    let input = "";
    if(e) {
      input = e[0].toUpperCase() + e.slice(1);
    }
    setInputCategory(input)
  }

    const handleAddCategory = () => {
      
      if (!inputCategory) {
        Swal.fire({
          icon: 'error',
          title: 'Categorie necompletata!',
          text: '',
        })
      } else {
            Axios.post(`${proxy}/server/category/add_category.php`, { inputCategory, inputOrdering }).then((response) => {

              if(response.data) {
                Swal.fire(
                  'Categorie adaugata!',
                  '',
                  'success'
                );
              }
              getCategories();
              setInputCategory('');
            }) 
            .catch(((err) => {
              console.error(err)
            }));
      }
      

      
    }



  return (
    <Box
    sx={{
      m: 1, width: '100%' , display: "flex", justifyContent: "center", alignItems: "center", gap: "5px"
    }}

  >

    <Input type="text" size="lg" placeholder="Nume Categorie" variant="outlined" color="primary" value={inputCategory} 
    onChange={(e) => handleInputCategory(e.target.value)}
    />

<Input type="number" size="lg" placeholder="Ordine" 
slotProps={{
  input: {
    min: 1,
    step: 1,
  },
}}
variant="outlined" color="primary" 
    onChange={(e) => setInputOrdering(e.target.value)}
    />


    <Button
        onClick={handleAddCategory}
        size="small"
        color="success" variant="contained"
        sx={{ height: "100%"}}>
            Adauga
    </Button> 
  </Box>
  )
}

export default AddCategory;