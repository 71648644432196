import React from 'react';
import classes from './CartItem.module.css';
import { Link } from 'react-router-dom';
import { MdOutlineClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart';

const CartItem = ({ cartProduct }) => {

  const cartTotal = useSelector(state => state.cart.cartTotal)

  const dispatch = useDispatch();

  const removeProductFromCart = (id) => {
    dispatch(cartActions.removeProductFromCart(id))
  }

  
  const removeItemFromCart = (id) => {
    dispatch(cartActions.removeItemtFromCart(id))
  }

  const addProductToCartHandler = (product) => {
    dispatch(cartActions.addProductToCart({
      id: product.id,
      title: product.title,
      price: product.price,
      image: product.image,
      link: product.link,
      product_link: product.product_link
    }))
  }

  return (
    <div className={classes.cart_items}>

      {cartProduct.length === 0 ? <div className={classes.empty_cart}>
        <h3>Coșul de cumpărături este gol.</h3>
        <p>Alegeți ceva din magazin!</p>
        <button><Link to={`${process.env.PUBLIC_URL}/`}>Acasă</Link></button>
      </div> 
      :
      <div className={classes.cart_head}>
            <p>Produs</p>
            <p>Preț</p>
            <p>Cantitate</p>
            <p>Total</p>
          </div>
      }
      
        {cartProduct.map((product) => (
            <div key={product.id} className={classes.cart_item}>
                <div className={classes.product_detail}>
                  <div className={classes.cart_item_img}>
                    <img src={product.image} alt={product.title}/>
                  </div>
                  <div>
                  <h3><Link to={`${process.env.PUBLIC_URL}/${product.link}/${product.product_link}`}>{product.title}</Link></h3>
                  </div>
                </div>
                <p className={classes.price}>{product.price} RON</p>
                <div className={classes.total_quantity}>
                  <button onClick={() => removeProductFromCart(product.id)}>-</button><p>{product.quantity}</p><button onClick={() => addProductToCartHandler(product)}>+</button>
                </div>
                <p className={classes.total_price}>{(product.totalPrice).toFixed(2)} RON</p>
                <button onClick={() => removeItemFromCart(product.id)} className={classes.delete_product}><MdOutlineClose /></button>
            </div>
        ))}

        {cartProduct.length > 0 && <div className={classes.next}>
          <p className={classes.cart_total}>Total: {cartTotal.toFixed(2)} RON</p>
        </div>}
    </div>
  )
}

export default CartItem