import React, { useEffect, useState } from 'react';
import styled from './Order.module.css';
import proxy from '../../../proxy';
import Axios from 'axios';
import Button from '@mui/material/Button';
import { cartActions } from '../../../store/cart';
import { useDispatch } from 'react-redux';

// ICONS
import { FiUser, FiMapPin, FiMail, FiPhone, FiCalendar, FiList } from 'react-icons/fi';
import { BsCartCheck, BsFillSignpost2Fill, BsCreditCard2Back, BsCash } from 'react-icons/bs';
import { TbArrowsDiagonal, TbArrowsDiagonalMinimize2 } from 'react-icons/tb';
import { SiGooglestreetview } from 'react-icons/si';
import { AiOutlineBarcode, AiOutlineShoppingCart } from 'react-icons/ai';
import { MdOutlinePaid } from 'react-icons/md';
import { CiDeliveryTruck } from 'react-icons/ci'

const Order = ({ orders, getOrders, clickHandler }) => {

    const [id, setID] = useState();
    const [sts, setSts] = useState();


    const [expand, setExpand] = useState({})

    const handleExpand = (id) => {
        setExpand({
            ...expand,
            [id]: !expand[id]
        })
    }

    const dispatch = useDispatch();

const orderID = (id) => {
    dispatch(cartActions.reducerIDOrder({
      id
    }))
  };


    const handleSts = (e) => {
        setSts(e.target.value);

    }

    useEffect(() => {
        Axios.post(`${proxy}/server/edit/editStatus.php`, {sts, id}).then((res) => {

            getOrders();
          })
          .catch(((err) => {
            console.error(err)
          }))
    }, [sts, id])


  return (

    // Order component
    <div className={styled.order}>

        {/* Table Head Order */}
        <div className={styled.table_head}>
                <div><AiOutlineBarcode /> <p>ID</p></div>
                <div><FiCalendar /> <p>Data </p></div>
                <div><FiUser /> <p>Nume & Prenume</p></div>
                <div><FiMapPin/><p>Oras</p></div>
                <div><FiMail/><p>Email</p></div>
                <div><MdOutlinePaid/><p>Plata</p></div>
                <div><CiDeliveryTruck /><p>Status</p></div>
                <div><BsCartCheck /><p>Total</p></div>
        </div>

        {/* Table Body Order*/}
        {
            !orders
            ?
            <p style={{color: "white", textAlign: "center", fontSize: '1.2rem'}}>
            Nu au fost găsite înregistrări care să se potrivească
          </p> 
          :
              Object.keys(orders).map((key, index) => {
                return  <div key={index} className={expand[index] ? `${styled.collapsed}` : ""}>
                            <div className={styled.table_body}>
                                <div>{orders[key].nrOrder}</div>
                                <div>{orders[key].orderDate}</div>
                                <div>{orders[key].client.fullName}</div>
                                <div>{orders[key].client.city}</div>
                                <div>{orders[key].client.email}</div>
                                <div className={styled.status}><p>{orders[key].status}</p> {orders[key].method === "card" ? <BsCreditCard2Back className={orders[key].status === "neplatita" ? styled.status_unpaid : styled.status_paid}/> : <BsCash className={orders[key].status === "neplatita" ? styled.status_unpaid : styled.status_paid}/>}</div>
                                <div>
                                <select className={styled.select} value={orders[key].statusOrder} selected
                                onChange={(e) => {handleSts(e); setID(orders[key].nrOrder)}}
                                >
                                    <option>in asteptare</option>
                                    <option>confirmata</option>
                                    <option>in curs de livrare</option>
                                    <option>livrata</option>
                                    <option>finalizata</option>
                                </select>
                                </div>
                                <div>{orders[key].totalPrice} RON</div>
                                <div className={styled.button}><button onClick={() => handleExpand(index)}>{expand[index] ? <TbArrowsDiagonalMinimize2 /> : <TbArrowsDiagonal />}</button></div>
                            </div>

                            {/* Order Collapse */}
                            <div className={expand[index] ? `${styled.collapse}` : `${styled.hide}`}>
                                <div className={styled.collapse_head}>
                                    <div><BsFillSignpost2Fill/> Cod Postal</div>
                                    <div><FiPhone/> Telefon</div>
                                    <div><SiGooglestreetview/> Adresa</div>
                                    <div><FiList/> Produse</div>
                                    <div></div>
                                </div>
                                <div className={styled.collapse_body}>
                                    <div>{orders[key].client.postal_code ? orders[key].client.postal_code : "-"}</div>
                                    <div>{orders[key].client.phone}</div>
                                    <div>{orders[key].client.address}</div>
                                    <div className={styled.products}>{orders[key].products.map((prod, id) => (
                                        <h4 key={id}><AiOutlineShoppingCart/><span style={{fontWeight: 500}}>{prod.title}</span> x {prod.quantity}</h4>
                                        ))}
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <Button variant="outlined" onClick={() => {clickHandler ('viewOrderDetails'); orderID(orders[key].nrOrder)}}>Detalii</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
              })
            }
    </div>
  )
}

export default Order