import React from 'react';
import Product from './Product';
import classes from "./ProductList.module.css";
import { DataContext } from '../../DataProvider';
import { useContext } from 'react';

const ProductList = () => {

    // Get products from DataProvider - (ContextAPI)
    const [products] = useContext(DataContext)

  return (
    <div className={classes.productlist}>

      {/* Send props for each product to component Product */}
      {
        [...products].filter(product => +product.active === 1).map((product) => {
          return <Product 
          key = {Number(product.id)}
          id = {Number(product.id)}
          image = {product.image_path}
          image_small = {product.image_path_small}
          image_icon = {product.image_path_icon}
          title={product.title}
          description={product.description}
          price = {Number(product.price)}
          category = {product.category}
          link={product.link}
          product_link={product.product_link}
          />
        })
      }
    </div>
  )
}

export default ProductList