import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Axios from 'axios';
import proxy from '../../../proxy';

const Category = ({ handleCategory, category }) => {
    const [allCategory, setAllCategory] = useState([]);


    useEffect(() => {
        Axios.get(`${proxy}/server/category/getCategories.php`).then((response) => {
            setAllCategory(response.data)
        })
      }, []);


  return (
    <Box sx={{ minWidth: 250 }}>
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categorie</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={category}
                label="Categorie"
                onChange={handleCategory}
            >
                {Object.values(allCategory).map(categ => (<MenuItem key={categ.id} value={categ.id}>{categ.name}</MenuItem>)
                )}
            </Select>
        </FormControl>
    </Box>
  )
}

export default Category