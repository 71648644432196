import React from 'react';
import styled from './Delivery.module.css'
import Page from '../UI/Page';
import {AiFillCheckCircle} from 'react-icons/ai'

const Returns = () => {
  return (
    <Page>
        <div className={styled.terms}>
            <div className={styled.terms_h2}>
                <AiFillCheckCircle />
                <h2>Politica de retur</h2>
            </div>
            <p>Ne dorim să vă oferim o experiență de cumpărare plăcută și, prin urmare, oferim posibilitatea de a returna produsele în termen de 14 zile de la data primirii acestora.</p>
            <p>Dacă doriți să returnați un produs, vă rugăm să ne contactați prin e-mai, furnizând detaliile comenzii și motivul pentru care doriți să returnați produsul.</p>
            <p>Produsele trebuie returnate în ambalajul original și în aceeași stare în care au fost primite, cu toate etichetele și accesoriile intacte.</p>
            <p>Taxele de transport pentru returnarea produselor vor fi suportate de către client.</p>
            <p>După primirea și verificarea produselor returnate, vă vom rambursa suma plătită pentru produse în termen de 14 zile.</p>
        </div>
    </Page>
  )
}

export default Returns