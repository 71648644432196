import React from 'react'
import Page from "../UI/Page"
import styled from './About.module.css';
import imgClock from '../../assets/clock.jpeg'

const About = () => {
  return (
    <Page>
      <div className={styled.about}>
        <h2>Despre noi</h2>
        <div className={styled.about_content}>
          <div className={styled.about_image}>
            <img src={imgClock} alt="Ceas Licheni" />
          </div>
          <div className={styled.about_text}>
          <p>Din pasiune pentru licheni a pornit din descoperirea si interesul pentru natura si dorinta de a aduce cat mai mult verde in casa ta</p>
          <ul>
            <li>Lichenii utilizați sunt antistatici, absorb umiditatea și nu sunt alergeni, toxici sau inflamabili. Nu necesită întreținere și nu sunt comestibili.</li>
            <li>Ne străduim să oferim produse de cea mai bună calitate, care să aducă un plus de naturalețe și frumusețe în orice spațiu.</li>
            <li>Suntem mândri de ceea ce facem și ne bucurăm să vedem cum lichenii noștri aduc un plus de verdeață și prospețime în casele și birourile oamenilor.</li>
            <li>Putem face la comanda diverse modele, cu o gama variată de culori.</li>
          </ul>
        </div>
        </div>
      </div>
    </Page>

  )
}

export default About;