import React, {useState } from 'react';
import Page from '../UI/Page';
import classes from './Cart.module.css'
import { useSelector } from 'react-redux';
import CartItem from './CartItem';
import Order from './Order';

const Cart = () => {

  const [order, setOrder] = useState(false);

function orderHandle(e) {
  setOrder(e);
}

  const cartProduct = useSelector(state => state.cart.items);

  return (
    <Page>
        {cartProduct.length > 0 && order ? <Order orderHandle={orderHandle} /> : <div className={classes.cart}>
          <h3>Coş de cumpărături</h3>
          <CartItem cartProduct={cartProduct}/>
          {cartProduct.length > 0 &&  <div>
                                        <button className={classes.btn_next} onClick={() => orderHandle(true)}>Continuați</button>
                                      </div>}
          {cartProduct.length > 0 && order && <Order />}
        </div>}
    </Page>
  )
}

export default Cart