import React from 'react'
import Page from '../UI/Page'
import HomeImage from '../HomeImage/HomeImage'
import CategoryProducts from './CategoryProducts'

const Category = () => {
  return (
    
    <Page>
        <HomeImage />
        <CategoryProducts />
    </Page>
  )
}

export default Category