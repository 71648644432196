import React, { useState, useEffect } from 'react';
import classes from "./Products.module.css";
import CategoryProductList from './CategoryProduct/CategoryProductList'
import Axios from 'axios';
import proxy from '../../proxy';
import { Link, useLocation } from 'react-router-dom';

const CategoryProducts = () => {
  const [categories, setCategories] = useState([]);
  let { pathname } = useLocation();
  
  useEffect(() => {
    Axios.get(`${proxy}/server/category/getCategories.php`).then((response) => {
      setCategories(response.data)
    });
  }, [pathname]);

  return (
    <div className={classes.products}>
      <div className={classes.category}>
      <Link to={"/"}>Toate produsele</Link>
        {Object.values(categories).sort((a, b) => +a.ord - +b.ord).map(category => ( <Link to={`${process.env.PUBLIC_URL}/${category.link}`} key={category.id}>{category.name}</Link>))}
      </div>
      
        <CategoryProductList />
    </div>
  )
}

export default CategoryProducts;