import React, {useState, useEffect} from 'react';
import styled from './Login.module.css';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { GrUserAdmin } from 'react-icons/gr';
import Axios from 'axios';
import Swal from 'sweetalert2';
import proxy from '../../proxy';
import { useCallback } from 'react';

const Login = ({ setToken }) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState("");
    const [admin, setAdmin] = useState();

    var md5 = require('md5');

    const getAdmin = useCallback(() => {
        username && Axios.post(`${proxy}/server/admin/`, {username}).then((response) => {
            const [admin] = response.data
            setAdmin(admin)
        }).catch(err => {
            console.error(err);
        })
      }, [username]);

      useEffect(() => {
        getAdmin();
      }, [getAdmin]);

    const handleSubmit = e => {
        e.preventDefault();
        if(!username && !password) {
            Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Campuri invalide',
            })
        } else if(admin) {
            if(admin.password !== md5(password)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Parola gresita',
                        })
            } else {
                setToken(admin.token);
            }
        } else Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Username incorect!',
                })
      }

  return (
    <form className={styled.login_wrapper} onSubmit={handleSubmit}>
        <div className={styled.login_admin}>
            <GrUserAdmin />
            <h1>Admin Panel</h1>
        </div>
        <TextField sx={{ width: '20rem' }} id="outlined-basic" label="Username" placeholder="Username" variant="outlined" onChange={e => setUsername(e.target.value)}/>

        <TextField sx={{ width: '20rem' }} id="outlined-basic" type="password" label="Password" placeholder="Password" variant="outlined" onChange={e => setPassword(e.target.value)}/>
        <div>
        <Button onClick={getAdmin} variant="contained"type="submit"  color="success">
                Login
        </Button>
        </div>
  </form>
  )
}

export default Login;

Login.propTypes = {
    setToken: PropTypes.func.isRequired
  }