import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from "./Dashboard.module.css";
import proxy from '../../../proxy';
import { BsBox, BsCart4, BsFillEyeSlashFill } from 'react-icons/bs'
import { BiCategory } from 'react-icons/bi';
import { MdDisabledVisible } from 'react-icons/md'

const Dashboard = () => {
    const [products, setProducts] = useState({
        totalProducts: null,
        productsInactive: null
      });
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getProducts();
        getOrders();
        getCategories();
      }, [])
  
      const getProducts = () => {
        Axios.get(`${proxy}/server/getProductsAdmin.php`, {params: {page, filter}}).then((response) => {

          setProducts({totalProducts: response.data.totalProducts, productsInactive: response.data.productsInactive})
        })
      }

      const getOrders = () => {
        Axios.get(`${proxy}/server/admin/orders/`).then((response) => {
          setOrders(response.data)
        })
      }

      const getCategories = () => {
        Axios.get(`${proxy}/server/category/admin_categories.php`).then((response) => {
            setCategories(response.data);
          })
    }



  return (
    <div className={styled.dashboard}>
        <div className={styled.dashboard_box}>

            <div className={`${styled.box} ${styled.box_products}`}>
                <div>
                    <h3>{products.totalProducts}</h3>
                    <p>Total produse</p>
                </div>
                <div className={styled.box_img}>
                    <BsBox />
                </div>
            </div>

            <div className={`${styled.box} ${styled.box_products_inactive}`}>
                <div>
                    <h3>{products.productsInactive}</h3>
                    <p>Produse inactive</p>
                </div>
                <div className={styled.box_img}>
                    <MdDisabledVisible />
                </div>
            </div>

            <div className={`${styled.box} ${styled.box_orders}`}>
                <div>
                    <h3>{orders.totalOrders}</h3>
                    <p>Total comenzi</p>
                </div>
                <div className={styled.box_img}>
                    <BsCart4 />
                </div>
            </div>

            <div className={`${styled.box} ${styled.box_categories}`}>
                <div>
                    <h3>{Object.keys(categories).length}</h3>
                    <p>Total categorii</p>
                </div>
                <div className={styled.box_img}>
                    <BiCategory />
                </div>
            </div>

            <div className={`${styled.box} ${styled.box_categories_inactive}`}>
                <div>
                    <h3>{Object.values(categories).filter(cat => +cat.active === 0).length}</h3>
                    <p>Categorii inactive</p>
                </div>
                <div className={styled.box_img}>
                    <BsFillEyeSlashFill />
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Dashboard