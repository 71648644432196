import React, { useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../store/cart';
import classes from "./Order.module.css";
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import Axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import proxy from '../../proxy';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { BsCashStack, BsCreditCard } from 'react-icons/bs'

const Order = ({ orderHandle }) => {
  const dispatch = useDispatch();
  const cartProduct = useSelector(state => state.cart.items);
  const cartTotal = useSelector(state => state.cart.cartTotal);

    const [method, setMethod] = useState("card");
    const [inputName, setInputName] = useState(null);
    const [inputSurname, setInputSurname] = useState(null);
    const [inputAddress, setInputAddress] = useState(null);
    const [inputCity, setInputCity] = useState(null);
    const [inputPostal, setInputPostal] = useState(null);
    const [inputEmail, setInputEmail] = useState(null);
    const [inputPhone, setInputPhone] = useState(null);
    const [inputId, setInputId] = useState(null);

    const generatorID = () => {

      let idOrder = "";
      let possible = "VBCDXFGHQJKLMNQPQRSTVVWXYZX123456789";
    
      for( let i=0; i < 6; i++ )
      idOrder += possible.charAt(Math.floor(Math.random() * possible.length));
  
      setInputId(`${String(idOrder)}`)
  }

  useEffect(() => {
    generatorID();
  }, []);

  const confirmEmail = (id) => {
    Axios.post(`${proxy}/server/email/`, {id}).then((res) => {

    })
    .catch(((err) => {
      console.error(err)
    }))
  }

const handleMethod = (e) => {
  setMethod(e.target.value);
}

const emptyCart = () => {
  dispatch(cartActions.emptyCart())
}

const submitInputs = () => {
  let validRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  if (!inputName || !inputSurname || !inputAddress || !inputCity || !inputEmail || !inputPhone) {
    Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Un camp nu este completat!',
  })
} else if (!inputEmail.match(validRegex)) {
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Email invalid!',
  })
}
else {

    Axios.post(`${proxy}/server/order/client/client.php`, {inputName, inputSurname, inputAddress, inputCity, inputPostal, inputEmail, inputPhone}).then((response) => {

      if (response.data.id_client) {
        const idClient = response.data.id_client
            Axios.post(`${proxy}/server/order/order.php`, {inputId, method, cartTotal, idClient}).then((response) => {

              if(response.data.id_order) {
                const idOrder = response.data.id_order;
                Axios.post(`${proxy}/server/order/order_detail/order_detail.php`, {idOrder, cartProduct}).then((response) => {
                  if(response.status) {
                    if(method === "card") {
                      window.location = `${proxy}/server/payment/cardRedirect.php?inputName=${inputName}&inputSurname=${inputSurname}&inputAddress=${inputAddress}&inputCity=${inputCity}&inputEmail=${inputEmail}&inputPhone=${inputPhone}&id=${inputId}&total=${cartTotal}`;
                    } else {
                      confirmEmail(inputId);
                      Swal.fire(
                        '',
                        'Comanda trimisa!',
                        'success',
                        emptyCart()
                      )
                    }
                  }
              });
              }
            });
            
      }
    });

    // emptyCart();
  }
};



  return (
    <div className={classes.section_order}>
      <div className={classes.order}>
          <p className={classes.head}>Completați adresa de livrare și detaliile de facturare</p>
          <form action="cardRedirect.php" method="post" name="frmPaymentRedirect">

              <div className={classes.full_name}>
                <input className={classes.name} onChange={(e) => setInputName(e.target.value)} type="text" autoComplete='off' placeholder='Nume *' />
                <input className={classes.surname} onChange={(e) => setInputSurname(e.target.value)} type="text" autoComplete='off' placeholder='Prenume *' />
              </div>
              <input onChange={(e) => setInputAddress(e.target.value)} type="text" autoComplete='off' placeholder='Adresa completă *' />
              <div className={classes.city_postal}>
                  <input onChange={(e) => setInputCity(e.target.value)} className={classes.city} type="text" autoComplete='off' placeholder='Oraș *' />
                  <input onChange={(e) => setInputPostal(e.target.value)} className={classes.postal} type="text" autoComplete='off' placeholder='Cod poștal' />
              </div>
              <input onChange={(e) => setInputEmail(e.target.value)} type="email" autoComplete='off' placeholder='Adresa de email *' />
              <input onChange={(e) => setInputPhone(e.target.value)} type="text" autoComplete='off' placeholder='Număr de telefon *' />

<div style={{display: 'flex', justifyContent:'center', gap: '2rem'}}>
<FormControlLabel sx={{fontSize: 12}}
        label="Curierat rapid"
        control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} defaultChecked color="success" />}
      />


<RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        name="controlled-radio-buttons-group"
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 25,
          }}}
          defaultValue="card"
        onChange={handleMethod}
      >
        <FormControlLabel className={classes.div_method} value="card" control={<Radio />} label={<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: "500"}}><BsCreditCard style={{fontSize: "25px"}}/><p>Card online</p></div>} />
        <FormControlLabel className={classes.div_method} value="cash" control={<Radio />} label={<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontWeight: "500"}}><BsCashStack style={{fontSize: "25px"}}/><p>Ramburs la curier</p></div>} />
      </RadioGroup>
</div>

      
          </form >
          <button onClick={submitInputs} className={classes.btn_next} type="submit">{method === "card" ? "Mergi spre plata" : "Trimite comanda"}</button>
      </div>
      <div className={classes.resume}>
        <h3>Rezumat comanda</h3>
        {cartProduct.map((product) => (
          <div className={classes.resume_product} key={product.id}>
            <div className={classes.resume_content}>
              <img src={product.image} alt="Produs"/>
              <div className={classes.resume_price}>
                <p className={classes.resume_title}>{product.title}</p>
                <div className={classes.resume_quantity}>
                  <p>{product.quantity}</p> X <p>{product.price} RON</p>
                </div>
              </div>
            </div>
            <p className={classes.resume_total}>{(product.totalPrice).toFixed(2)} RON</p>
          </div>
        ))}
        <p className={classes.cart_total}>Total: {cartTotal.toFixed(2)} RON</p>
        <div className={classes.div_btn}>
          <button className={classes.btn_next} onClick={() => orderHandle(false)}>Inapoi</button>
        </div>
      </div>
    </div>
  )
}

export default Order