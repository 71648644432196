import React from 'react';
import styled from './Contact.module.css';
import Page from '../UI/Page';
import ContactUs from "../../assets/contact.png";
import { RiMailLine, RiSmartphoneLine} from 'react-icons/ri'

const Contact = () => {
  return (
    <Page>
      <div className={styled.page_contact}>
        <div className={styled.contact}>
          <a href="tel:40724142165"><RiSmartphoneLine /><span>+40724 142 165</span></a>
          <a href="mailto:office@almsolutions.ro"><RiMailLine />office@almsolutions.ro</a>
          <h4>ABILITY LOGICAL AND MORE SOLUTIONS SRL</h4>
          <p><span>Sediu Social:</span> Prel. Ghencea nr.350A, Bl. 3, Ap. 97, Cam. 4, Et. 9, Sector 6, București</p>
          <p><span>CIF:</span> RO45425885 <span>RC:</span> J40/67/2022</p>
        </div>

        <div className={styled.contact_img}>
          <img src={ContactUs} alt="Contact Us" />
        </div>
      </div>
    </Page>
  )
}

export default Contact