import React, { useEffect, useState } from 'react';
import styled from './Reports.module.css';
import Axios from 'axios';
import proxy from '../../../proxy';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

// ICONS
import { AiOutlineFieldNumber } from 'react-icons/ai';
import {  MdOutlineShoppingCart } from 'react-icons/md';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi'
import { GrMoney } from 'react-icons/gr'

const Reports = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [reports, setReports] = useState([]);

    const getReports = () => {
        Axios.get(`${proxy}/server/admin/reports.php`, { params: { startDate, endDate }}).then((response) => {
            setReports(response.data)
          })
    };

    useEffect(() => {
        getReports();
    }, [])

  return (
    <div className={styled.reports}>

            <div className={styled.range_date}>
                   
                <div className={styled.report_orders}>
                    <p>Total comenzi - </p>
                    <p>{reports.totalOrders}</p>
                    <MdOutlineShoppingCart />
                </div>

                <div className={styled.date_picker}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Data start"
                            inputFormat="DD/MM/YYYY"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Data sfarsit"
                            inputFormat="DD/MM/YYYY"
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </div>


                <Button variant="contained" color="success" onClick={getReports}>
                    Genereaza raport
                </Button>
        </div>


            {
                +reports.numberOfOrders === 0 
                ?
                <p style={{textAlign: "center", fontSize: '1.5rem'}}>Nu au fost gasite rezultate!</p>
                :
                <div className={styled.data_reports}>
                <div className={`${styled.report} ${styled.report_1}`}>
                    <p>Numar comenzi</p>
                    <div className={styled.report_item}>
                        <AiOutlineFieldNumber />
                        <p>{reports.numberOfOrders}</p>
                    </div>
                </div>
        
                <div className={`${styled.report} ${styled.report_2}`}>
                    <p>Valoarea medie a comenzii</p>
                    <div className={styled.report_item}>
                        <GrMoney/>
                        <p>{reports.avgOrders} RON</p>
                    </div>
                </div>

                <div className={`${styled.report} ${styled.report_3}`}>
                    <p>Cea mai mare comanda</p>
                    <div className={styled.report_item}>
                        <BiTrendingUp />
                        <p>{reports.biggestOrder} RON</p>
                    </div>
                </div>

                <div className={`${styled.report} ${styled.report_4}`}>
                    <p>Cea mai mica comanda</p>
                    <div className={styled.report_item}>
                        <BiTrendingDown />
                        <p>{reports.smallestOrder} RON</p>
                    </div>
                </div>

                <div className={`${styled.report} ${styled.report_5}`}>
                    <p>Cel mai vandut produs</p>
                    <div className={`${styled.report_item} ${styled.sold_product}`}>
                        <img src={`${proxy}/server/images/icon/${reports.bestSoldProduct?.image_path}`}></img>
                        <p>{reports.bestSoldProduct?.title}</p>
                        <p>({reports.bestSoldProduct?.sold})</p>
                    </div>
                </div>
        
            </div>

            }

    </div>
  )
}

export default Reports