import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../UI/Page'
import styled from "./Terms.module.css"
import {AiFillCheckCircle} from 'react-icons/ai'

const Terms = () => {
    const { pathname } = useLocation();


    useEffect(() => {
        window.scrollTo(0, 0);
      }, [pathname]);

  return (
    <Page>
        <div className={styled.terms}>

        <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>Politica GDPR</h2>
                </div>
                <p>Politica noastră de protecție a datelor conform Regulamentului General privind Protecția Datelor (GDPR) se concentrează pe protejarea confidențialității și siguranța datelor personale ale utilizatorilor site-ului nostru.</p> 
                <p>Colectăm și procesăm doar datele necesare pentru a oferi serviciile noastre, iar acestea sunt protejate prin măsuri de securitate adecvate. </p>
                <p>Utilizatorii au dreptul de a accesa, rectifica sau șterge datele lor personale, precum și dreptul de a se opune prelucrării acestora. </p>
                <p>Dacă aveți orice întrebări sau preocupări cu privire la politica noastră de protecție a datelor, vă rugăm să ne contactați.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>Politica de confidenţialitate</h2>
                </div>
                <p>Politica noastră de confidențialitate se concentrează pe protejarea informațiilor personale ale utilizatorilor site-ului nostru.</p>
                <p>Colectăm doar informațiile necesare pentru a oferi serviciile noastre și asigurăm că acestea sunt protejate prin măsuri de securitate adecvate.</p>
                <p>Nu vindem sau distribuim informațiile personale ale utilizatorilor către terți.</p>
                <p>Utilizatorii au dreptul de a accesa, rectifica sau șterge informațiile lor personale, precum și dreptul de a se opune prelucrării acestora.</p>
                <p>Dacă aveți orice întrebări sau preocupări cu privire la politica noastră de confidențialitate, vă rugăm să ne contactați.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>1.Definiții</h2>
                </div>
                <p>Termenii și condițiile generale prevăzute în continuare se vor aplica tuturor vânzărilor de bunuri de către Lizi-Art și partenerii săi, prin intermediul magazinului virtual www.lizi-art.ro către Cumpărător și pot fi modificate oricând de către Lizi-Art fără notificare prealabilă.</p> 
                <p>Astfel, următorii termeni vor însemna:</p>
                <p>Cumpărător – persoană fizică / persoană juridică sau altă entitate juridică ce emite o Comandă.</p>
                <p>Vânzător – Lizi-Art, cu denumirea comercială ABILITY LOGICAL AND MORE SOLUTIONS SRL, având sediul social în Prel. Ghencea nr.350A, Bl. 3, Ap. 97, Cam. 4, Et. 9, Sector 6, București, CUI RO45425885, nr. de înregistrare la Registrul Comerțului J40/67/2022.</p>
                <p>Bunuri – orice produs, inclusiv documentele și serviciile menționate în Comandă, care urmează a fi furnizate de către Vânzător Cumpărătorului.</p>
                <p>Comandă – un document electronic ce intervine ca formă de comunicare între Vânzător și Cumpărător, prin care Vânzătorul este de acord să livreze Bunurile și Cumpărătorul este de acord să primească aceste Bunuri și să facă plată acestora.</p>
                <p>Contract – o Comandă confirmată de către Vânzător.</p>
                <p>Drepturi de Proprietate Intelectuală – toate drepturile imateriale cum ar fi know-how, dreptul de autor și drepturi în natură de autor, drepturile de bază de date, drepturi de proiectare, drepturi de model, patente, mărci înregistrate și înregistrări ale numelor de domenii pentru oricare din cele de mai sus.</p>
                <p>Site – domeniul www.lizi-art.ro și subdomeniile acestuia.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>2.Documente Contractuale</h2>
                </div>
                <p>Prin lansarea unei Comenzi electronice pe site-ul www.lizi-art.ro, Cumpărătorul este de acord cu forma de comunicare (e-mail, telefon) prin care Vânzatorul își derulează operațiunile. Comanda va fi compusă din următoarele documente:</p> 
                <p>Comanda (împreună cu mențiunile clare asupra datelor de livrare și facturare) și condițiile sale specifice.</p>
                <p>Termeni și condiții</p>
                <p>Daca Vânzătorul confirmă Comanda, acest lucru implică o acceptare completă a termenilor Comenzii. Acceptarea Comenzii de către Vânzător se consideră finalizată atunci când există o confirmare electronică (e-mail) din partea Vânzătorului către Cumpărător, fără a necesita o confirmare de primire din partea acestuia. Vânzătorul nu consideră în nici un moment o comandă neconfirmată ca având valoarea unui Contract.</p>
                <p>Confirmarea Comenzii se face electronic (e-mail). Prețurile produselor din comandă sunt valabile 3 zile lucrătoare de la data înregistrării comenzii. Termenii și condițiile generale de vânzare vor sta la baza Contractului astfel încheiat.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>3.Obligațiile Vânzătorului</h2>
                </div>
                <p>Vânzătorul își va utiliza cunoștințele sale profesionale și tehnice pentru a atinge rezultatul stipulat în Comandă și va livra Bunurile care îndeplinesc cerințele și specificațiile Cumpărătorului exprimate în Comandă;</p> 
                <p>Informațiile prezentate pe site-urile Vânzătorului au caracter informativ și pot fi modificate de către Vânzător, fără o anunțare prealabilă.</p>
                <p>Descrierile produselor pot fi incomplete, însă vânzătorul face eforturi de a prezenta informațiile cele mai relevante, pentru ca produsul să fie utilizat în parametrii pentru care a fost achiziționat;</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>4.Politica de cookie-uri</h2>
                </div>
                <p>Un cookie este un fişier text care conţine mici fragmente de informaţii trimise navigatorului tău şi memorate în computerul, telefonul tău mobil sau alt dispozitiv, atunci când vizitezi un site. Acest fişier cookie trimite informaţii înapoi către site ori de câte ori îl revizitezi.</p> 
                <p>Cookie-urile pot fi permanente (cunoscute drept cookie-uri persistente), care rămân în computerul tău până când le ştergi, sau temporare (cunoscute drept cookie-uri pentru sesiune) care sunt valabile doar până când închizi fereastra navigatorului tău. Cookie-urile pot fi ale părţii-gazdă (first-party), care sunt configurate de site-ul pe care îl vizitezi, sau ale unor părţi terţe, care sunt configurate de un site diferit de cel pe care îl vizitezi.</p>
                <p>Cum utilizeaza Lizi-Art cookie-urile?</p>
                <p>Utilizăm cookie-urile pentru a îmbunătăţi funcţionalitatea site-urilor noastre, pentru a te ajuta să navighezi mai eficient de la o pagină la alta, pentru a memora preferinţele tale şi, în general, pentru a îmbunătăţi experienţa utilizatorilor. Cookie-urile pe care le folosim pe site-urile noastre pot fi din următoarele categorii:</p>
                <p>Cookie-uri strict necesare:</p>
                <p>Aceste cookie-uri sunt esenţiale pentru ca tu să poţi naviga pe site şi să foloseşti serviciile pe care le-ai solicitat, cum ar fi accesarea zonelor sigure ale site-ului.</p>
                <p>Folosim acest tip de cookie-uri pentru a administra înregistrarea şi autentificarea utilizatorului. Fără aceste cookie-uri, serviciile pe care le-ai solicitat nu pot fi furnizate. Aceste cookie-uri sunt ale părţii-gazdă (first-party) şi pot fi permanente sau temporare. Pe scurt, site-urile noastre nu vor funcţiona corespunzător fără aceste cookie-uri.</p>
                <p>Cookie-uri pentru performanţă:</p>
                <p>Aceste cookie-uri colectează informaţii despre cum utilizează vizitatorii un site, de exemplu care sunt paginile cele mai vizitate. Aceste cookie-uri nu colectează informaţii care identifică vizitatori individuali. Toate informaţiile colectate de aceste cookie-uri sunt cumulate şi anonime.</p>
                <p>Folosim aceste cookie-uri pentru a:</p>
                <p>realiza statistici despre cum sunt utilizate site-urile noastre măsura impactul campaniilor noastre publicitare</p>
                <p>Aceste cookie-uri pot fi permanente sau temporare, ale părţii-gazdă sau ale unor părţi terţe. Pe scurt, aceste cookie-uri colectează informaţii anonime despre paginile vizitate şi reclamele vizualizate.</p>
                <p>Cookie-uri pentru funcţionalitate:</p>
                <p>Aceste cookie-uri permit unui site să memoreze lucrurile alese de tine (cum ar fi numele de utilizator, limba sau ţara în care te afli) şi oferă opţiuni îmbunătăţite, mai personale. Aceste cookie-uri pot fi de asemenea utilizate pentru a furniza servicii pe care le-ai solicitat, cum ar fi vizionarea unui videoclip sau comentariul pe un blog. Informaţiile utilizate de aceste cookie-uri sunt anonimizate şi ele nu pot înregistra activităţile tale de navigare pe alte site-uri.</p>
                <p>Folosim aceste cookie-uri pentru a:</p>
                <p>memora dacă ai beneficiat deja de un anumit serviciu</p>
                <p>îmbunătăţi experienţa în general pe tot site-ul, memorând preferinţele tale.</p>
                <p>Cookie-uri pentru publicitate:</p>
                <p>Aceste cookie-uri sunt utilizate pentru a limita de câte ori vezi o reclamă, precum şi pentru a măsura impactul campaniilor publicitare.</p>
                <p>Cookie-urile pentru publicitate sunt folosite pentru a gestiona publicitatea pe tot site-ul.</p>
                <p>Cookie-urile pentru publicitate sunt plasate de terţe părţi, cum ar fi companiile de publicitate şi agenţii lor, şi pot fi cookie-uri permanente sau temporare. Pe scurt, ele sunt legate de serviciile de publicitate furnizate pe site-ul nostru de terţe părţi.</p>
                <p>Cookie-uri pentru sociale:</p>
                <p>– aceste cookies sunt folosite de retelele de socializare (Facebook, Twitter, Google+, YouTube, Instagram, Pinterest, etc) si permit distribuirea continutului de pe www.lizi-art.ro pe respectivele retele. Lizi-Art.ro nu controleaza aceste cookies, deci, pentru mai multe informatii despre cum functioneaza, te rugam sa verifici paginile retelelor de socializare.</p>
                <p>Cum să administrezi & ştergi cookie-uri</p>
                <p>Dacă vrei să impui restricţii, să blochezi sau să ştergi fişiere cookie, poţi s-o faci modificând setările navigatorului tău de web. Utilizarea www.lizi-art.ro fără respingerea cookie-urilor sau a tehnologiilor similare denotă consimţământul vizitatorilor pentru utilizarea de către noi a unor astfel de tehnologii şi pentru prelucrarea informaţiilor.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>5.Limitarea răspunderii administratorului site-ului</h2>
                </div>
                <p>Lizi-Art nu isi asuma obligatia si nu garanteaza implicit sau expres, pentru continutul site-ului, respectiv pentru continutul oferit de partenerii sai sau de Utilizatorii site-ului.</p> 
                <p>Cu toate acestea, Lizi-Art va depune toate eforturile rezonabile pentru a asigura acuratetea si maniera profesionista in care vor fi furnizate informatiile pe site, pentru a dobandi si mentine increderea Utilizatorilor in site.</p>
                <p>In acest sens, Lizi-Art va incerca sa corecteze in cel mai scurt timp posibil erorile si omisiunile semnalate.</p>
                <p>Administratorul site-ului nu ofera niciun fel de garantii pentru continutul site-ului si in nicio situatie nu poate fi tinut raspunzator pentru nicio pierdere sau prejudiciu ce ar putea rezulta din utilizarea oricarei parti/secvente/pagini de pe site sau din imposibilitatea utilizarii acesteia, indiferent de cauza acesteia ori din interpretarea eronata a oricaror prevederi ale continutului site-ului.</p>
                <p>Informatiile furnizate prin intermediul site-ului sunt oferite cu buna-credinta, din surse apreciate ca fiind de incredere. In cazul in care vreunul din articolele publicate sau orice alta informatie intra sub incidenta legii dreptului de autor, rugam Utilizatorii sa ne contacteze la adresa de e-mail office@almsolutions.ro, pentru a putea lua masurile ce se impun.</p>
                <p>Totodată, Utilizatorii trebuie sa aibă în vedere faptul ca informatia prezentata poate include eventuale informatii inexacte (de ex.: date de ordin tehnic sau erori de tastat).</p>
                <p>Administratorul site-ului va face toate diligentele necesare pentru a corecta in cel mai scurt timp posibil aceste aspecte.</p>
                <p>Utilizatorii inteleg si accepta faptul ca Lizi-Art nu garanteaza:</p>
                <p>ca informatiile continute pe site sunt pe deplin complete;</p>
                <p>ca informatiile introduse de Utilizatorii website-ului sunt reale, corecte si nu isi asuma raspunderea pentru modul in care vizitatorii le folosesc;</p>
                <p>ca informatiile sau serviciile de pe site  vor satisface toate cerintele Utilizatorilor, iar pentru folosirea inadecvata a acestora Utilizatorii isi asuma intreaga responsabilitate;</p>
                <p>pentru rezultatele obtinute de Utilizatori ca urmare a folosirii informatiilor sau serviciilor disponibile prin intermediul site-ului utilizarea informatiilor si a serviciilor facandu-se de catre Utilizatori pe propria raspundere;</p>
                <p>ca serviciile disponibile prin intermediul site-ului vor functiona constant, neintrerupt, fara erori – fata de acest aspect, Lizi-Art nu isi asuma responsabilitatea pentru eventualele pagube pe care Utilizatorii le-ar putea avea din cauza nefunctionarii temporare sau defectuoase a site-ului sau pentru folosirea informatiilor obtinute prin utilizarea link-urilor de pe site catre alte site-uri (utilizarea lor este la latitudinea Utilizatorilor).</p>
                <p>De asemenea, Utilizatorii inteleg si accepta faptul ca Lizi-Art nu este responsabila pentru eventuale inadvertente, erori sau omisiuni in cadrul informatiilor furnizate pe site de catre Utilizatori.</p>
                <p>Totodata, Utilizatorii inteleg si accepta ca Lizi-Art este absolvita de orice raspundere pentru mesajele publicitare postate pe site sau prin intermediul serviciilor oferite prin site, precum si pentru bunurile sau serviciile furnizate de catre autorii acestor mesaje publicitare.</p>
                <p>In mod expres, Utilizatorii site-ului sunt de acord sa exonereze de raspundere Lizi-Art pentru orice actiune judiciara sau extrajudiciara, care provine ca urmare a utilizarii incorecte sau frauduloase a site-ului.</p>
            </div>

            <div>
                <div className={styled.terms_h2}>
                    <AiFillCheckCircle />
                    <h2>Modificarea termenilor și condițiilor</h2>
                </div>
                <p>Lizi-Art are dreptul de a modifica oricând și în orice mod oricare dintre prevederile cuprinse în Termeni și condiții sau Termenii și Condițiile în întregime, fără nicio notificare prealabila și fără a fi obligata sa îndeplinească vreo alta formalitate fata de Utilizatori.</p>
                <p>Orice modificare este acceptata deplin și necondiționat de către Utilizatorii site-ului prin simpla utilizare sau accesare a site-ului sau a oricărei facilitați oferite de site, intervenite oricând după operarea modificării, iar neacceptarea oricărei modificări atrage obligația respectivului Utilizator de a înceta de îndată accesarea site-ului și/sau utilizarea în orice mod a serviciilor oferite prin intermediul acestuia.</p>
            </div>
        </div>
    </Page>
  )
}

export default Terms