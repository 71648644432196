import React,  { useState } from 'react';
import styled from './Slider.module.css';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft, FaWindowClose } from 'react-icons/fa';

const Slider = ( { images, handleSlide, currentImg } ) => {
    const [current, setCurrent] = useState(currentImg);
    const slides = Object.values(images)
    const length = slides.length;
  
    const nextSlide = () => {
      setCurrent(current === length - 1 ? 0 : current + 1);
    };
  
    const prevSlide = () => {
      setCurrent(current === 0 ? length - 1 : current - 1);
    };
  

  
    return (

      <section className={styled.slider}>
        <FaWindowClose className={styled.close} onClick={handleSlide}/>
        <FaArrowAltCircleLeft className={styled.left_arrow} onClick={prevSlide}/>
        <FaArrowAltCircleRight className={styled.right_arrow} onClick={nextSlide} />
        {slides.map((slide, index) => (
            <div
              className={index === current ? styled.active : styled.slide}
              key={index}
            >
              {index === current && (
                <img src={slide} alt='product slider' className={styled.image} />
              )}
            </div>
          )
        )}
      </section>

    );
  };
  

export default Slider