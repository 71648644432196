import React, { useState, useEffect } from 'react';
import proxy from '../../proxy';
import classes from "./Products.module.css";
import ProductList from './Product/ProductList';
import Axios from 'axios';
import { Link } from 'react-router-dom';

const Products = () => {

  // STATE for Categories
  const [categories, setCategories] = useState([]);

  // useEffect GET Category
  useEffect(() => {
    Axios.get(`${proxy}/server/category/getCategories.php`).then((response) => {
      setCategories(response.data)
    })
  }, []);

  return (
    <div className={classes.products}>
      <div className={classes.category}>

          {/* Display each category*/}
          {
          Object.values(categories).sort((a, b) => +a.ord - +b.ord).map(category => 
          (<Link to={`${process.env.PUBLIC_URL}/${category.link}`} 
          key={category.id}>{category.name}</Link>))
          }
      </div>

      {/* Component ALL PRODUCTS */}
        <ProductList />
    </div>
  )
}

export default Products