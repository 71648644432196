import React, { useState, useRef } from 'react';
import classes from "./AddProduct.module.css";
import Axios from 'axios';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Swal from 'sweetalert2';
import proxy from '../../../proxy';
import Category from './Category';
import Description from './Description';


// ICONS
import { MdAddCircleOutline } from 'react-icons/md'

const AddProduct = () => {
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState(0);
    const [description, setDescription] = useState('Descriere produs');
    const [category, setCategory] = useState('');
    const [imgupload, setImgupload] = useState({
      name: null,
      file: null,
      src: null
    });
    const [imgsupload, setImgsupload] = useState([]);

    const handleCategory = (event) => {
      setCategory(event.target.value);
    };

    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        setDescription(editorRef.current.getContent())
      }
    };

    const onChangeImg = (e) => {
      let url = URL.createObjectURL(e.target.files[0])
      setImgupload({
        name: `${Math.floor(Math.random() * 5000)}_${e.target.files[0].name.toLowerCase().replaceAll(" ",  "-")}`,
        file: e.target.files[0],
        src: url
      })
    }
    

    const onChangeImgs = (e) => {
      let url = URL.createObjectURL(e.target.files[0])

      const ifExistImg = imgsupload.some(img => {
        if(img.file.name === e.target.files[0].name) {
          return true
        }

        return false
      })

      if(!ifExistImg) {
        setImgsupload(( imgsupload ) => ([...imgsupload, {
          name: `${Math.floor(Math.random() * 5000)}_${e.target.files[0].name.toLowerCase().replaceAll(" ",  "-")}`,
          file: e.target.files[0],
          src: url
        }
      ]))
      } else {
        Swal.fire(
          'Imaginea exista deja!',
          '',
          'error'
        )
      }
    }

    const deletePreviewImg = (e) => {
      setImgsupload(( imgsupload ) => (imgsupload.filter((img, index) => index !== e)))
    }

    const uploadImages = () => {
      let formImages = new FormData();
      imgsupload.forEach((img, index) => {
        formImages.append('img'+ index, img.file)
        formImages.append('file' + index, img.name)
      })

      Axios.post(`${proxy}/server/upload/uploadImages.php`, formImages).then((res) => {

      })
      .catch(((err) => {
        console.error(err)
      }))

    }


    const uploadHandler = () => {

      const formData = new FormData();
      formData.append('image', imgupload.file)
      formData.append('filename', imgupload.name)

      Axios.post(`${proxy}/server/upload/upload.php`, formData).then((res) => {

      })
      .catch(((err) => {
        console.error(err)
      }))
      }


      // Admin - add product
    const submitProduct = async() => {
      if (!title) {
        Swal.fire(
          'Titlul produsului nu a fost completat!',
          '',
          'error'
        )
      } else if (!price) {
        Swal.fire(
          'Pretul produsului nu a fost completat!',
          '',
          'error'
        )
      } else if (!category) {
        Swal.fire(
          'Categoria produsului nu a fost selectata!',
          '',
          'error'
        )
      } else if (!imgupload.name) {
        Swal.fire(
          'Imaginea principala produsului nu a fost adaugata!',
          '',
          'error'
        )
      } else {
        uploadHandler();
        Axios.post(`${proxy}/server/post/addProduct.php`, {title, price, image_path: imgupload.name, description, category, imgsupload}).then((response) => {

        });
        Swal.fire(
          'Produs adaugat!',
          '',
          'success'
        )
        setTitle("");
        setPrice(0);
        setDescription('Descriere produs');
        setCategory("");
        setImgupload({
          name: null,
          file: null
        });
        setImgsupload([]);
      }

    }

  return (

        <FormLabel sx={{padding: '3rem'}} className={classes.form} encType="multipart/form-data">

          <TextField sx={{ width: '40%' }} className={classes.form_input} id="outlined-basic" label="Titlu" placeholder="Titlu" variant="outlined" value={title} onChange={(e) => {setTitle(e.target.value)}}/>

          <TextField sx={{ width: '40%' }} className={classes.form_input} id="filled-number" type="number" label="Preț" placeholder="Preț" variant="outlined" value={price} onChange={(e) => {setPrice(e.target.value)}}/>

          <Category handleCategory={handleCategory} category={category}/>

        {/* IMAGES */}
          <div style={{display: 'flex', gap: '.5rem'}}>
            
            {/* MAIN IMAGE */}
            <div  style={{display: 'flex', flexDirection: "column", gap: ".5rem", justifyContent: 'center', alignItems: 'center'}}>

              {imgupload.src && <div className={classes.img_preview}>
                                  <img src={imgupload.src}></img>
                                  <button onClick={() => setImgupload({name: null, file: null, src: null})}>Șterge</button>
                                </div>
              }
              
              {imgsupload.src && <div className={classes.img_preview}>
                  <img src={imgsupload.src} alt={imgsupload.name}></img>
                  <button onClick={() => setImgsupload({name: null, file: null, src: null})}>Sterge</button>
                </div>
                }

              <Button className={classes.btn_images} variant="contained" component="label">
                <MdAddCircleOutline/> <p>{imgupload.src ? "Schimba" : "Adauga" } imagine principala</p>
                <input hidden accept="image/*" multiple type="file" onChange={onChangeImg}/>
              </Button>
            </div>

                {/* IMAGES PRODUCT */}
              <div style={{display: 'flex', flexDirection: "column", gap: ".5rem", justifyContent: 'center', alignItems: 'center'}}>
                <div className={classes.imgs_preview}>
                  {imgsupload.map((img, index) => (
                    <div key={index} className={classes.img_preview}>
                      <img src={img.src} alt={img.name}></img>
                      <button onClick={() => deletePreviewImg(index)}>Sterge</button>
                    </div>
                  ))}
                </div>

              {imgupload.file && <Button className={classes.btn_images} variant="contained" component="label">
                                    <MdAddCircleOutline/> <p>Adauga imagine</p>
                                    <input hidden accept="image/*" multiple type="file" onChange={onChangeImgs}/>
                                  </Button>
              }
              </div>

          </div>


          <Description log={log} editorRef={editorRef} description={description}/>

            <Button variant="contained" color="success" size="large" onClick={() => {uploadImages(); submitProduct()}} >
                Adaugă produs
            </Button>
        </FormLabel>
  )
}

export default AddProduct