import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: localStorage.getItem("items")  ? JSON.parse(localStorage.getItem("items")) : [],
        totalProducts: localStorage.getItem("totaleProducts")  ? JSON.parse(localStorage.getItem("totaleProducts")) : 0,
        cartTotal: localStorage.getItem("cartTotal")  ? JSON.parse(localStorage.getItem("cartTotal")) : 0,
        quantity: localStorage.getItem("quantity")  ? JSON.parse(localStorage.getItem("quantity")) : 0,
        productEdit: {}
    },
    reducers: {

        // REDUCER ADD CART PRODUCT
        addProductToCart(state, action) {
            const newProduct = action.payload;
            const existingProduct = state.items.find((item) => item.id === newProduct.id);
            state.totalProducts++;
                if (!existingProduct) {
                    state.items.push({
                        id: newProduct.id, 
                        title: newProduct.title,
                        quantity: 1,
                        price: newProduct.price, 
                        image: newProduct.image,
                        link: newProduct.link,
                        product_link: newProduct.product_link,
                        totalPrice: newProduct.price})
                        state.cartTotal = state.cartTotal + newProduct.price
                } else {
                    existingProduct.quantity = existingProduct.quantity + 1;
                    existingProduct.totalPrice = existingProduct.totalPrice + existingProduct.price;
                    state.cartTotal = state.cartTotal + existingProduct.price
                }
                localStorage.setItem("items", JSON.stringify(state.items))
                localStorage.setItem("totaleProducts", JSON.stringify(state.totalProducts))
                localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal))
                localStorage.setItem("quantity", JSON.stringify(state.quantity))
            },

        // REDUCER REMOVE PRODUCT FROM CART
        removeProductFromCart(state, action) {
            const id = action.payload;
            const existingProduct = state.items.find(item => item.id === id);
            state.totalProducts--;
            if (existingProduct.quantity === 1) {
                state.items = state.items.filter((item) => item.id !== id);
                state.cartTotal = state.cartTotal - existingProduct.price;
            } else {
                existingProduct.quantity--
                existingProduct.totalPrice = existingProduct.totalPrice - existingProduct.price;
                existingProduct.totalProducts = existingProduct.totalProducts - 1;
                state.cartTotal = state.cartTotal - existingProduct.price;
            }
            localStorage.setItem("items", JSON.stringify(state.items))
            localStorage.setItem("totaleProducts", JSON.stringify(state.totalProducts))
            localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal))
            localStorage.setItem("quantity", JSON.stringify(state.quantity))
        },

        // REDUCER REMOVE ITEM FROM CART
        removeItemtFromCart(state, action) {
            const id = action.payload;

            const existingProduct = state.items.find(item => item.id === id);

            state.totalProducts = state.totalProducts - existingProduct.quantity
            if (existingProduct) {
                state.items = state.items.filter((item) => item.id !== id);
                state.cartTotal = state.cartTotal - existingProduct.totalPrice;
            }
            localStorage.setItem("items", JSON.stringify(state.items))
            localStorage.setItem("totaleProducts", JSON.stringify(state.totalProducts))
            localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal))
            localStorage.setItem("quantity", JSON.stringify(state.quantity))
        },

         // REDUCER EMPTY CART
        emptyCart(state, action) {
            state.items = [];
            state.totalProducts = 0;
            state.cartTotal = 0;
            state.quantity = 0
            localStorage.setItem("items", JSON.stringify(state.items))
            localStorage.setItem("totaleProducts", JSON.stringify(state.totalProducts))
            localStorage.setItem("cartTotal", JSON.stringify(state.cartTotal))
            localStorage.setItem("quantity", JSON.stringify(state.quantity))
        },

        
         // REDUCER PRODUCT ID FOR EDIT
        productEditID(state, action) {
            state.product = action.payload;
        },

        reducerCategoryName(state, action) {
            state.category = action.payload;
        },

        reducerIDOrder(state, action) {
            state.idOrder = action.payload;
            console.log(state.idOrder);
        }


        
        },
        
    });

export const cartActions = cartSlice.actions;

export default cartSlice;