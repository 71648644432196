import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import styled from "./Orders.module.css"
import Order from './Order';
import FilterOrders from './FilterOrders';
import PaginationItems from '../../PaginationItems/PaginationItems';
import proxy from '../../../proxy';

const Orders = ({ clickHandler }) => {

    const [orders, setOrders] = useState(null);

    const [idComanda, setIdComanda] = useState("");
    const [name, setName] = useState("");
    const [city, setCity] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState(null);
    const [paid, setPaid] = useState(null);
    const [mtd, setMtd] = useState(null);
    const [page, setPage] = useState(1);


    const handleReset = () => {
      setIdComanda("");
      setName("");
      setCity("");
      setEmail("");
      setDate(null);
      setPaid(null);
      setMtd("");
      setPage(1);
  }

  const handleChangePage = (event, value) => {
    setPage(value);
  };

    const getOrders = () => {
      Axios.get(`${proxy}/server/admin/orders/`, {params: {id: idComanda, date, name, city, email, page, paid, mtd}}).then((response) => {
        setOrders(response.data)
      })
    };

    useEffect(() => {
      getOrders();
  }, [idComanda, name, city, email, date, page, paid, mtd])

  return (
    <div className={styled.orders}>
      <FilterOrders getOrders={getOrders} setIdComanda={setIdComanda} setName={setName} 
      setEmail={setEmail} setCity={setCity} setDate={setDate} handleReset={handleReset}
      date={date} idComanda={idComanda} name={name} city={city} email={email} setPaid={setPaid} setMtd={setMtd} paid={paid} mtd={mtd}/>

    <Order orders={orders?.orders} getOrders={getOrders}  clickHandler={clickHandler}/>

<PaginationItems pages={orders?.pages} handleChangePage={handleChangePage} page={page}/>
    </div>
  )
}

export default Orders