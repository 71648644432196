import React, { useState } from 'react';
import classes from "./Product.module.css";
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { cartActions } from '../../../store/cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';

const CategoryProduct = (props) => {
  
  const {id, title, image, price, category, link, product_link, image_small, image_icon } = props;
  
  const [animation, setAnimation] = useState(false)

  const dispatch = useDispatch()

  const addProductToCartHandler = () => {
    dispatch(cartActions.addProductToCart({
      id,
      title,
      price,
      image: image_icon,
      category,
      link,
      product_link
    }))
  }

  const animationHandle = e => {
    setAnimation(true)
   setTimeout(() => {
    setAnimation(false)
   }, 1550)
  }

  return (
    <div className={classes.product}>
      <Link to={`${process.env.PUBLIC_URL}/${link}/${product_link}`} className={classes.product_item}>
        
        <div className={classes.product_head}>
          <div className={classes.product_image}>
            <img src={image_small} alt="produs" />
          </div>
          <div className={classes.title}>
            <h4>{title}</h4><span>&rarr;</span>
          </div>
        </div>

        <div className={classes.price}>
          <p>Pret: </p><span>{price} RON</span>
        </div>

      </Link>
        <a href="/#" className={`${classes.button} ${animation && classes.animat}`} onClick={() => {addProductToCartHandler(); animationHandle()}}><FontAwesomeIcon icon={faCartArrowDown} className={classes.icon} /> 
          <p>Adaugă in coș</p>
        </a>
  </div>

  )
}

export default CategoryProduct;