import React, { useEffect, useState } from 'react';
import styled from './OrderDetails.module.css'
import proxy from '../../../proxy';
import Axios from 'axios';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// ICONS
import { AiOutlineCalendar } from 'react-icons/ai'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdLocalShipping, MdLocationOn } from 'react-icons/md'

const OrderDetails = ({ clickHandler }) => {

const [order, setOrder] = useState(useSelector(state => state.cart.idOrder));
const idOrder = useSelector(state => state.cart.idOrder);
const [id, setID] = useState();
const [sts, setSts] = useState();
const [status, setStatus] = useState();
console.log(idOrder)

const getOrder = () => {
    Axios.post(`${proxy}/server/admin/orders/orderDetails.php`, idOrder).then((response) => {
        console.log(response.data)
        setOrder(response.data)
      })
}

const handleSts = (e) => {
    setSts(e.target.value);

}

const handleStatus = (e) => {
    setStatus(e.target.value);

}

    useEffect(() => {
        getOrder();
        }, [])

    useEffect(() => {
        Axios.post(`${proxy}/server/edit/editStatus.php`, {sts, id}).then((res) => {

            getOrder();
            })
            .catch(((err) => {
            console.error(err)
            }))
    }, [sts, id])

    useEffect(() => {
        Axios.post(`${proxy}/server/edit/editPayment.php`, {status, id}).then((res) => {
console.log(res.data)
            getOrder();
            })
            .catch(((err) => {
            console.error(err)
            }))
    }, [status, id])

  return (
    <div className={styled.OrderDetails}>
        <div className={styled.order_date}>
            <div>
                <div className={styled.date}>
                    <AiOutlineCalendar /><p>{order?.order_date}</p>
                </div>
                <p className={styled.id}>#ID {order?.nrOrder}</p>
            </div>


        <div style={{display: "flex", gap: "1rem"}}>
            <div>
            <p>Status</p>
            <select value={order?.status_order} selected onChange={(e) => {handleSts(e); setID(order?.nrOrder)}}>
                <option>in asteptare</option>
                <option>confirmata</option>
                <option>in curs de livrare</option>
                <option>livrata</option>
                <option>finalizata</option>
            </select>
            </div>
            <div>
                {order?.method === "card" ? 
                    <p>Plata: <span>{order?.status}</span></p>
                :
                <>
                    <p>Plata</p>
                    <select value={order?.status} selected onChange={(e) => {handleStatus(e); setID(order?.nrOrder)}}>
                        <option>platita</option>
                        <option>neplatita</option>
                    </select>
                </>}

            </div>
        </div>

        </div>

        <div className={styled.client}>
            <div className={styled.client_box}>
                <BsFillPersonFill />
                <div>
                    <h3>Client</h3>
                    <p>{order?.full_name}</p>
                    <p>{order?.email}</p>
                    <p>{order?.phone}</p>
                    <div></div>
                </div>
            </div>
            <div className={styled.client_box}>
            <MdLocalShipping />
                <div>
                    <h3>Livrare</h3>
                    <p>Transport: <span>Curier</span></p>
                    <p>Metoda plata: <span>{order?.method}</span></p>
                    <p>Status: <span>{order?.status_order}</span></p>
                    <p>Plata: <span>{order?.status}</span></p>
                    <div></div>
                </div>
            </div>
            <div className={styled.client_box}>
            <MdLocationOn />
                <div>
                    <h3>Adresa</h3>
                    <p>Localitate: <span>{order?.city}</span></p>
                    <p>Adresa: <span>{order?.address}</span></p>
                    <p>Cod postal: <span>{order.postal_code ? order.postal_code  : "-"}</span></p>
                    <a href={`https://www.google.com/maps/search/${order?.city}+${order?.address}}`} target='_blank'>Deschide in MAPS</a>
                    <div></div>
                </div>
            </div>
        </div>

        <div className={styled.products}>
            <table className={styled.content_table}>
                <thead>
                    <tr>
                        <th>Produs</th>
                        <th>Cod produs</th>
                        <th>Cantitate</th>
                        <th>Pret unitar</th>
                        <th>Pret total</th>
                    </tr>
                </thead>
                <tbody>
                    {order?.products?.map(p => (
                                    <tr key={p?.id}>
                                    <td style={{textAlign: "left"}}>{p?.title}</td>
                                    <td>{p?.sku}</td>
                                    <td>{p?.quantity}</td>
                                    <td>{p?.price} RON</td>
                                    <td>{(p?.quantity * p?.price).toFixed(2)} RON</td>
                                </tr>
                    ))}
                    </tbody>
                <tfoot>
                <tr>
                <td colSpan="5" style={{textAlign: "right"}}><strong>Total: {order.total_price} RON</strong></td>
                </tr>
            </tfoot>
            </table>
        </div>

        <div style={{display: 'flex', justifyContent: "flex-end"}}>
            <Button variant="contained" onClick={() => clickHandler("viewOrders")}>Inapoi la comenzi</Button>
        </div>
    </div>
  )
}

export default OrderDetails;