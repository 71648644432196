import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import proxy from '../../../proxy';
import styled from './CategoryAdmin.module.css';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCategory from './AddCategory';

// ICONS
import { AiFillEye, AiFillEyeInvisible, AiFillEdit, AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { BsSortNumericDown } from 'react-icons/bs'

const CategoryAdmin = () => {
    const [categories, setCategories] = useState([]);

        const [ordCategories, setOrdCategories] = useState([]);


    const [viewDivCategory, setViewDivCategory] = useState(false);
    const [editInput, setEditInput] = useState("");
    const [editOrder, setEditOrder] = useState(false)

    const handleEdit = (e) => {
      let input = "";
      if(e) {
        input = e[0].toUpperCase() + e.slice(1);
      }

      setEditInput(input)
    }


    const handleOrdering = (id, set) => {


      const matchingCategories = ordCategories.filter((cat) => cat.id === id);

      if (matchingCategories.length > 0) {

        const updatedCategories = ordCategories.map((cat) =>
          cat.id === id ? {...cat, set:set} : cat
        );
        setOrdCategories(updatedCategories);
      } else {

        setOrdCategories([...ordCategories, {id, set}]);
      }

    }

    const handleEditInput = (index, set) => {

      setCategories(categories => {
        const newState = categories.map((cat, i) => {
          if (i === index) {
            return {...cat, editable: set}
          }
          return cat;
        })

        return newState;
      })

    }


    const getCategories = () => {
      Axios.get(`${proxy}/server/category/admin_categories.php`).then((response) => {
        const data = response.data;
          setCategories(data);
        })
  }


  const handleActiveCategory = (id, active) => {
    Axios.post(`${proxy}/server/category/setActiveCategory.php`, {id: id, active: active}).then((response) => {

      getCategories();
    })
  }

    useEffect(() => {
      getCategories();
    }, []);


    const updateCategory = (id) => {
      Axios.post(`${proxy}/server/edit/updateCategory.php`, {id, editInput} ).then((response) => {

        getCategories();
  })
    }

    const ordCategory = () => {
      Axios.post(`${proxy}/server/edit/ordCategory.php`, ordCategories ).then((response) => {

        setEditOrder(false);
        getCategories();
  })
    }

    const deleteCategory = (id) => {
        Swal.fire({
            title: 'Esti sigur ca vrei sa stergi aceasta categorie?',
            showDenyButton: true,
            confirmButtonText: 'Sterge',
            denyButtonText: 'Renunta',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
          })
          .then((result) => {
            if (result.isConfirmed) {
                Axios.delete(`${proxy}/server/category/delete_category.php`, { data: {id} }).then((response) => {

                    if(response.data) {
                      Swal.fire('Produsul a fost sters!', '', 'success').then(() => {
                        getCategories();
                      })
                    } else {
                      Swal.fire("Categoria este asociata unui produs si nu poate fi stearsa", "", 'error');
                    }
              })

            } else if (result.isDenied) {
              Swal.fire('Categoria nu a fost sters!', '', 'info')
            }
          });
    }

  return (
    <div className={styled.categories_admin}>

        <div className={styled.btn_add}>
            <Button className={styled.btn} variant="contained" size="large" onClick={() => setViewDivCategory(!viewDivCategory)}>{viewDivCategory ? <MdRemoveCircleOutline /> : <MdAddCircleOutline/>}</Button>
        </div>

        {viewDivCategory && <AddCategory getCategories={getCategories} />}

        <div className={styled.categories_head}>
            <p>Nume</p>
            <p>Produse</p>
            <div style={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
              <p>Ordine</p>
              <Button color={editOrder ? "primary" : "success"} variant="contained"
              sx={{padding: "2px 10px", minWidth: "0"}}
              className={styled.category_btn}
              onClick={() => {editOrder ? ordCategory() : setEditOrder(true)}}
              >{editOrder ? <AiFillCheckCircle /> : <BsSortNumericDown />}
              </Button>
            </div>
            <p></p>
        </div>


<div style={{position: "relative"}}>
        {
            categories.sort((a, b) => b.active - a.active).map((category, index) => {
                return <div key={index} className={+category.active === 0 ? `${styled.categories_body} ${styled.inactive}` : `${styled.categories_body}`}
                >
                            <p className={+categories[index].editable === 1 ? styled.edit : ""} contentEditable={+categories[index].editable === 1} suppressContentEditableWarning={+categories[index].editable === 1}
                            onInput={(e) => handleEdit(e.target.innerHTML)} >{category.name}</p>
                            <p>{category.Count}</p>
                            <p onInput={(e) => handleOrdering(+category.id, e.target.innerHTML)} className={editOrder ? styled.edit : ""} contentEditable={editOrder} suppressContentEditableWarning={editOrder} type="number">{category.ord}</p>

                            <div className={styled.btn_group} disabled>

                                              <Button size="small"
                                                  disabled={+categories[index].editable === 1}
                                                  sx={{minWidth: "0", padding: "10px 15px", zIndex: "3"}}
                                                  onClick={() => handleActiveCategory(category.id, (+category.active ? 0 : 1))}
                                                  color="success" variant="contained"
                                                  className={styled.category_btn}>
                                                    {+category.active ? <AiFillEye /> : <AiFillEyeInvisible />}
                                              </Button>

                              {
                                +categories[index].editable === 1 ? 
                                <>
                                  <Button  size="small"
                                    disabled={editInput.length > 0 ? false : true}
                                    sx={{minWidth: "0", padding: "10px 15px"}}
                                    color="primary" variant="contained"
                                    onClick={() => updateCategory(category.id)}
                                    className={styled.category_btn}>
                                    <AiFillCheckCircle />
                                  </Button>

                                  <Button  size="small"
                                      color="error" variant="contained"
                                      sx={{minWidth: "0", padding: "10px 15px"}}
                                      onClick={() => {handleEditInput(index, 0); setEditInput("")}}
                                      className={styled.category_btn}>
                                    <AiFillCloseCircle />
                                  </Button>
                                </>
                                :
                                                              
                                <Button size="small"
                                  color="primary" variant="contained"
                                  sx={{minWidth: "0", padding: "10px 15px"}}
                                  onClick={() => handleEditInput(index, 1)}
                                  className={styled.category_btn}>
                                  <AiFillEdit />
                                </Button>
                              }

                              <Button
                                  onClick={() => deleteCategory(category.id)}
                                  size="small"
                                  sx={{minWidth: "0", padding: "10px 15px"}}
                                  disabled={+categories[index].editable === 1 || +category.active === 0}
                                  color="error" variant="contained"
                                  className={styled.category_btn}>
                                  <DeleteIcon  />
                              </Button> 

                            </div>
                        </div>

            })
        }
</div>
    </div>
  )
}

export default CategoryAdmin