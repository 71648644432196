import React, { useState, useEffect } from 'react';
import classes from "./Header.module.css";
import { useSelector } from 'react-redux';
import "../../index.css";
import { TiShoppingCart } from 'react-icons/ti';
import { Link, NavLink } from 'react-router-dom';
import {RiFacebookCircleLine, RiInstagramLine, RiMailLine, RiSmartphoneLine} from 'react-icons/ri';
import {ReactComponent as Moss} from '../../assets/moss.svg';
import { Toolbar, IconButton, Drawer, ListItemButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import {MenuList} from '@mui/material';

const Header = () => {
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const productsTotal = useSelector(state => state.cart.totalProducts);


  const displayDesktop = () => {
    return (
      <>
                  <li><NavLink to={`${process.env.PUBLIC_URL}/`}>Acasă</NavLink></li>
                  <li><NavLink to={`${process.env.PUBLIC_URL}/despre`}>Despre noi</NavLink></li>
                  <li><NavLink to={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink></li>
      </>
    );
  };


  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar sx={{padding: 0}}>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <Menu sx={{fontSize: "3rem"}}/>
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
                  <MenuList className={classes.menu_mobile} sx={{marginTop: "35%"}}>
                  <ListItemButton><NavLink to={`${process.env.PUBLIC_URL}/`}>Acasă</NavLink></ListItemButton>
                  <ListItemButton><NavLink to={`${process.env.PUBLIC_URL}/despre`}>Despre noi</NavLink></ListItemButton>
                  <ListItemButton><NavLink to={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink></ListItemButton>
                  </MenuList>
        </Drawer>

      </Toolbar>
    );
  };

  return (
    <div className={classes.header_div}>
        <div className={classes.social}>
            <a className={classes.instagram} href="tel:40724142165"><RiSmartphoneLine /><span>0724 142 165</span></a>
            <a className={classes.facebook} href="https://www.facebook.com/Din-pasiune-pentru-licheni-111851347949213"><RiFacebookCircleLine /></a>
            <a className={classes.instagram} href="https://www.instagram.com/din_pasiune_pentru_licheni/"><RiInstagramLine /></a>
            <a className={classes.instagram} href="mailto:office@almsolutions.ro"><RiMailLine /></a>
        </div>
        <div className={classes.header}>
        <Link to={`${process.env.PUBLIC_URL}/`} className={classes.logo}>
            <Moss className={classes.logoSvg} />
            <div className={classes.logoText}>
              <h2 style={{fontFamily: "Springtime"}}>lizi-art.ro</h2>
              <p style={{fontFamily: "Springtime"}}>Din pasiune pentru licheni</p>
            </div>
            {/* <img src={Logo} alt="logo" /> */}
        </Link>
        <nav className={classes.nav}>
          
            <ul>
                  {/* <li><NavLink to={`${process.env.PUBLIC_URL}/`}>Acasă</NavLink></li>
                  <li><NavLink to={`${process.env.PUBLIC_URL}/despre`}>Despre</NavLink></li>
                  <li><NavLink to={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink></li> */}

                {mobileView ? displayMobile() : displayDesktop()}

                <li><NavLink to={`${process.env.PUBLIC_URL}/cart`} className={classes.nav_bag}><TiShoppingCart /><p>{productsTotal}</p></NavLink></li>
            </ul>
        </nav>
        </div>
    </div>
  )
}

export default Header