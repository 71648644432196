import React from 'react';
import styled from './Delivery.module.css'
import Page from '../UI/Page';
import {AiFillCheckCircle} from 'react-icons/ai'

const Delivery = () => {
  return (
    <Page>
        <div className={styled.terms}>
            <div className={styled.terms_h2}>
                <AiFillCheckCircle />
                <h2>Politica de livrare comandă</h2>
            </div>
            <p>Livrarea produselor noastre se face in timp util si eficient prin intermediul companiilor de curierat. </p>
            <p>Termenul estimat de livrare variaza in functie de disponibilitatea produselor si locatia de livrare, in medie produsele sunt livrate in 3-7 zile lucratoare de la confirmarea comenzii.</p>
            <p>In cazul in care un produs este temporar epuizat sau nu poate fi livrat la locatia dorita, va vom notifica si vom face tot posibilul sa gasim o solutie convenabila pentru dumneavoastra.</p>
            <p>Va rugam sa ne contactati daca aveti intrebari sau nelamuriri cu privire la termenul de livrare al produselor.</p>
        </div>
    </Page>
  )
}

export default Delivery