import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import CategoryProduct from './CategoryProduct'
import classes from "./ProductList.module.css";
import proxy from '../../../proxy';
import { useLocation } from 'react-router-dom';

const CategoryProductList = () => {
  const [products, setProducts] = useState([])
  let location = useLocation();

  const url = location.pathname.split("/")

  

useEffect(() => {
   const productByCategory = () => Axios.get(`${proxy}/server/category/products_by_category.php`, { params: { categoryName: url[1] } })
   .then((response) => {

      setProducts(response.data)
    }).catch((err) => console.log("err", err));;
    productByCategory()
}, [location.pathname]);


  return (
    <div className={classes.productlist}>
      {
        products.filter(product => +product.active === 1).map((product) => {
          return <CategoryProduct 
          key = {Number(product.id)}
          id = {Number(product.id)}
          image = {product.image_path}
          image_small = {product.image_path_small}
          image_icon = {product.image_path_icon}
          title={product.title}
          description={product.description}
          price = {Number(product.price)}
          category = {product.category}
          link = {product.link}
          product_link = {product.product_link}
          />
        })
      }
    </div>
  )
}

export default CategoryProductList;