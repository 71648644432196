import React, { useState } from 'react';
import AddProduct from './AddProduct/AddProduct';
import classes from "./Admin.module.css";
import Products from './Products/Products';
import Orders from './Orders/Orders';
import Edit from './Edit/Edit';
import Login from '../Login/Login';
import Dashboard from "./Dashboard/Dashboard"
import Reports from './Reports/Reports';
import CategoryAdmin from './CategoryAdmin/CategoryAdmin';
import OrderDetails from './OrderDetails/OrderDetails';
import useToken from './useToken';

// ICONS
import { BsBox, BsCart4 } from 'react-icons/bs';
import { AiOutlineDashboard } from 'react-icons/ai';
import { BiCategory } from 'react-icons/bi';
import { TbReport } from 'react-icons/tb'
import { useEffect } from 'react';


const Admin = () => {
  const { token, setToken } = useToken();

  const [title, setTitle] = useState("Dashboard")
  const [state, setState] = useState({
    dashboard: false,
    viewAdd: false,
    viewProducts: false,
    viewOrders: false,
    viewEdit: false,
    viewCategories: false,
    viewReports: false,
    viewOrderDetails: false
  })

  const { dashboard, viewAdd, viewProducts, viewOrders, viewEdit, viewCategories, viewReports, viewOrderDetails } = state;

  const clickHandler = (value) => {
    const newState = { ...state };
    const nextValue = !newState[value];
    Object.keys(newState).forEach(key => newState[key] = false);
    newState[value] = nextValue;
    setState(newState);
  };
  
  useEffect(() => {
    clickHandler("dashboard")
  }, []);

  const handleTitle = (string) => {
    setTitle(string)
  }

  // const [dashboard, setDashboard] = useState(true)
  // const [viewAdd, setViewAdd] = useState(false)
  // const [viewProducts, setViewProducts] = useState(false)
  // const [viewOrders, setViewOrders] = useState(false)
  // const [viewEdit, setViewEdit] = useState(false)
  // const [viewCategories, setViewCategories] = useState(false)
  // const [viewReports, setViewReports] = useState(false)

  if(!token) {
    return <Login setToken={setToken} />
  }



  // const handleEdit = () => {
  //   setDashboard(false)
  //   setViewAdd(false);
  //   setViewProducts(false);
  //   setViewOrders(false);
  //   setViewEdit(true);
  //   setViewCategories(false)
  // }

  // const handleAddProduct = () => {
  //   setDashboard(false)
  //   setViewAdd(true);
  //   setViewProducts(false);
  //   setViewOrders(false);
  //   setViewEdit(false);
  //   setViewCategories(false)
  // }

  return (
    <div className={classes.admin}>
      <h2>Admin Panel - lizi-art.ro | {title}</h2>
      <div className={classes.container}>

        <div className={classes.buttons}>
        <button className={`${classes.buttons_btn} ${dashboard && classes.btn_active}`} 
          onClick={() => {clickHandler('dashboard'); handleTitle("Dashboard")}} ><AiOutlineDashboard/><p>Dashboard</p></button>
          <button className={`${classes.buttons_btn} ${viewProducts && classes.btn_active}`}
          onClick={() => {clickHandler('viewProducts'); handleTitle("Produse")}} ><BsBox /><p>Produse</p></button>
          <button className={`${classes.buttons_btn} ${viewCategories && classes.btn_active}`}
          onClick={() => {clickHandler('viewCategories'); handleTitle("Categorii")}} ><BiCategory /><p>Categorii</p></button>
          <button className={`${classes.buttons_btn} ${viewOrders && classes.btn_active}`}
          onClick={() => {clickHandler('viewOrders'); handleTitle("Comenzi")}}  ><BsCart4/><p>Comenzi</p></button>
          <button className={`${classes.buttons_btn} ${viewReports && classes.btn_active}`}
          onClick={() => {clickHandler('viewReports'); handleTitle("Raporturi")}}  ><TbReport/><p>Raporturi</p></button>

          {/* <button className={`${classes.buttons_btn} ${dashboard && classes.btn_active}`} 
          onClick={() => {setDashboard(true); setViewAdd(false); setViewProducts(false); setViewOrders(false); setViewEdit(false); setViewCategories(false)}} ><AiOutlineDashboard/><p>Dashboard</p></button>
          <button className={`${classes.buttons_btn} ${viewProducts && classes.btn_active}`}
          onClick={() => {setDashboard(false); setViewAdd(false); setViewProducts(true); setViewOrders(false); setViewEdit(false); setViewCategories(false)}} ><BsBox /><p>Produse</p></button>
          <button className={`${classes.buttons_btn} ${viewCategories && classes.btn_active}`}
          onClick={() => {setDashboard(false); setViewAdd(false); setViewProducts(false); setViewOrders(false); setViewEdit(false); setViewCategories(true)}} ><BiCategory /><p>Categorii</p></button>
          <button className={`${classes.buttons_btn} ${viewOrders && classes.btn_active}`}
          onClick={() => {setDashboard(false); setViewAdd(false); setViewProducts(false); setViewOrders(true); setViewEdit(false); setViewCategories(false)}}  ><BsCart4/><p>Comenzi</p></button>
          <button className={`${classes.buttons_btn} ${viewOrders && classes.btn_active}`}
          onClick={() => {setDashboard(false); setViewAdd(false); setViewProducts(false); setViewOrders(true); setViewEdit(false); setViewCategories(false)}}  ><TbReport/><p>Raporturi</p></button> */}
        </div>

        {dashboard && <Dashboard />}
        {viewAdd && <AddProduct />}
        {viewProducts && <Products clickHandler={clickHandler} handleTitle={handleTitle}/>}
        {viewOrders && <Orders clickHandler={clickHandler} handleTitle={handleTitle}/>}
        {viewEdit && <Edit />}
        {viewCategories && <CategoryAdmin/>}
        {viewReports && <Reports />}
        {viewOrderDetails && <OrderDetails clickHandler={clickHandler}/>}
      </div>
      
      </div>
  )
}

export default Admin