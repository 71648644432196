import React, { useEffect } from 'react';
import styled from './Success.module.css'
import { useParams } from 'react-router-dom';
import Img from "../../assets/success.png"
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart';
import proxy from '../../proxy';
import Axios from 'axios';

const Success = () => {
  const { id } = useParams();

    const dispatch = useDispatch();


    const emptyCart = () => {
        dispatch(cartActions.emptyCart());
      }

      const confirmEmail = (id) => {
        Axios.post(`${proxy}/server/email/`, {id}).then((res) => {


        })
        .catch(((err) => {
          console.error(err)
        }))
      }

      useEffect(() => {
        confirmEmail(id);
        emptyCart();
      }, []);
      
  return (
            <div className={styled.return}>
	
                <div className={styled.content}>
                    <img className={styled.content_img} src={Img} />
                    <h3>Plata efectuata cu success!</h3>

                    <p>Va multumim!</p>
                    <p>Comanda dumneavoastra a fost inregistrata! 
                        Echipa <strong>lizi-art.ro</strong> urmeaza sa o expedieze cat mai curand posibil 
                        Veti primi un e-mail cu detaliile comenzi.</p>
                    <button><a href='https://lizi-art.ro/'>Inapoi la pagina principala</a></button>
                </div>

        </div>

  )
}

export default Success