import React, { useState, useEffect } from 'react';
import Page from '../UI/Page';
import Axios from 'axios';
import proxy from '../../proxy';
import classes from "./PageProduct.module.css";
import Slider from './Slider';
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { cartActions } from '../../store/cart';
import { Link } from 'react-router-dom';

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { GiHand } from 'react-icons/gi';
import { BsFillPinMapFill } from 'react-icons/bs';

const PageProduct = () => {

  const [animation, setAnimation] = useState(false);
  const [product, setProduct] = useState({});
  const [slides, setSlides] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const [images, setImages] = useState([]);
  const [currentImg, setCurrentImg] = useState(0);
  const {productName} = params

  let { pathname } = useLocation();

  const getProduct = () => {
    Axios.post(`${proxy}/server/getProduct.php`, {productName} ).then((response) => {

      setProduct(response.data)
      const product = response.data;
      setImages([product.image_path, ...product.images.map(img => img.image_path)]);
    });
  }

  const handleSlide = () => {
    setSlides(!slides);
  }

  const handleCurrentImg = (index) => {
    setCurrentImg(index)
  }

  useEffect(() => {
    getProduct();
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const addProductToCartHandler = () => {
    dispatch(cartActions.addProductToCart({
      id: +product.id,
      title: product.title,
      price: +product.price,
      image: product.image_path_icon,
      category: product.category,
      link: product.link,
      product_link: product.product_link
    }))
  }

  const animationHandle = e => {
    setAnimation(true)
    setTimeout(() => {
    setAnimation(false)
   }, 1550)
  }

  return (
    <Page>

      <div className={classes.productItem}>
      {slides && <Slider images={images} handleSlide={handleSlide} currentImg={currentImg}/>}
        <div className={classes.nav}>
          <Link to="/">Acasa</Link> /<Link to={`/${params.category}`}>{product?.category}</Link>
           / {product?.title}
        </div>
        <div className={classes.product}>
          <div>
            <figure className={classes.productImage} onClick={() => {handleCurrentImg(0); handleSlide()}}>
              <img src={product?.image_path_medium} alt="Product" />
            </figure>
            <div className={classes.product_images}>
              {product?.images && product.images.map((img, index) => (
                            <figure key={index} className={classes.images_img} onClick={() => {handleCurrentImg(index + 1); handleSlide()}}>
                              <img src={img.image_path_small} alt="Product" />
                            </figure>
              ))}
            </div>
          </div>
            <div className={classes.productDetail}>
              <div className={classes.title}>
                  <h4>{product?.title}</h4>
                  <div className={classes.price}>
                  <p>Pret: </p><span>{product?.price} RON</span>
                  </div>
              </div>

              <div className={classes.productSizeDetails}>
                <div dangerouslySetInnerHTML={{__html: product?.description}} />
              </div>

              <a className={`${classes.button} ${animation && classes.animat}`} onClick={() => {addProductToCartHandler(); animationHandle()}}><FontAwesomeIcon icon={faCartArrowDown} className={classes.icon} /> 
                <p>Adaugă in coș</p>
              </a>

              <div className={classes.sku}>
                <p>Cod produs: <strong>{product?.sku}</strong></p>
              </div>
            </div>
        </div>
      </div>

      <div className={classes.footer_product}>
        <div className={classes.footer_item}>
            <GiHand />
          <p>100% HANDMADE</p>
        </div>
        <div className={classes.footer_item}>
          <BsFillPinMapFill />
          <p>100% BRAND ROMANESC</p>
        </div>
      </div>
    </Page>
  )
}

export default PageProduct;