import React, {useState, useRef, useEffect, useCallback} from 'react';
import classes from './Edit.module.css';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import BundledEditor from '../../../BundledEditor';
import { useSelector } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import Category from '../AddProduct/Category'
import proxy from '../../../proxy';
import { MdAddCircleOutline } from 'react-icons/md'

const Edit = () => {

    const state = useSelector(state => state.cart.product);


    const [product, setProduct] = useState(state.product);

    const [imgupload, setImgupload] = useState({
      name: product.image_path,
      file: null,
      src: null
    });
    const [deleteImg, setIDeleteImg] = useState(false);
    const [images, setImages] = useState([]);
    const [title, setTitle] = useState(product.title);
    const [price, setPrice] = useState(product.price);
    const [tempImage, setTempImage] = useState("");
    const [description, setDescription] = useState(product.description);
    const [category, setCategory] = useState(product.id_category);
    const [imgsupload, setImgsupload] = useState([]);


    const handleTempImage = () => {
      if (!deleteImg) {
        setTempImage(product.image_path)
      }
    }
  
    const getImages = () => {
      Axios.post(`${proxy}/server/getImages.php`, {id: +product.id}).then((res) => {
        setImages(res.data)
      })
      .catch(((err) => {
        console.error(err)
      }))
    };

    const deleteImage = useCallback((id, image) => {
      Axios.delete(`${proxy}/server/delete/deleteImage.php`, {data: {id, image}}).then((res) => {
        getImages();
      })
      .catch(((err) => {
        console.error(err)
      }))
    }, [])

    useEffect(() => {
      getImages();
    }, [])
  

    const editorRef = useRef(null);
    const log = () => {
      if (editorRef.current) {
        setDescription(editorRef.current.getContent());
      }
    };

    const handleCategory = (event) => {
      setCategory(event.target.value);
    };

    const onChangeImg = (e) => {
      let url = URL.createObjectURL(e.target.files[0])
      setImgupload({
        name: `${Math.floor(Math.random() * 5000)}_${e.target.files[0].name.toLowerCase().replaceAll(" ",  "-")}`,
        file: e.target.files[0],
        src: url
      })
    }

    const onChangeImgs = (e) => {
      let url = URL.createObjectURL(e.target.files[0])

      const ifExistImg = imgsupload.some(img => {
        if(img.file.name === e.target.files[0].name) {
          return true
        }

        return false
      })

      if(!ifExistImg) {
        setImgsupload(( imgsupload ) => ([...imgsupload, {
          name: `${Math.floor(Math.random() * 5000)}_${e.target.files[0].name.toLowerCase().replaceAll(" ",  "-")}`,
          file: e.target.files[0],
          src: url
        }
      ]))
      } else {
        Swal.fire(
          'Imaginea exista deja!',
          '',
          'error'
        )
      }
    }

    const deletePreviewImg = (e) => {
      setImgsupload(( imgsupload ) => (imgsupload.filter((img, index) => index !== e)))
    }

    const uploadImages = () => {
      let formImages = new FormData();
      imgsupload.forEach((img, index) => {
        formImages.append('img'+ index, img.file)
        formImages.append('file' + index, img.name)
      })

      Axios.post(`${proxy}/server/upload/uploadImages.php`, formImages).then((res) => {

      })
      .catch(((err) => {
        console.error(err)
      }))

    }


    const updateImages = () => {
      Axios.post(`${proxy}/server/edit/updateImages.php`, {id: product.id, imgsupload}).then((response) => {

      });
    }

    const uploadHandler = () => {

      const formData = new FormData();
      formData.append('image', imgupload.file)
      formData.append('filename', imgupload.name)

      Axios.post(`${proxy}/server/upload/upload.php`, formData).then((res) => {

      })
      .catch(((err) => {
        console.error(err)
      }))
      }

    const updateProduct = () => {
        Axios.put(`${proxy}/server/edit/editProduct.php`, {title, price, image_path: imgupload.name, description, id: product.id, category, tempImage}).then((response) => {

        });
        Swal.fire(
          'Produs editat!',
          '',
          'success'
        )
    }

  return (
<>
    {Object.keys(product).length > 0  &&
    <FormLabel sx={{padding: '3rem 0'}} className={classes.form} encType="multipart/form-data">

      <TextField enabled="true" sx={{ width: '40%' }} className={classes.form_input} id="outlined-basic" label="Titlu" placeholder="Titlu" variant="outlined" value={title} onChange={(e) => {setTitle(e.target.value)}}/>

      <TextField sx={{ width: '40%' }} className={classes.form_input} id="filled-number" type="number" label="Pret" placeholder="Pret" variant="outlined" value={price} onChange={(e) => {setPrice(e.target.value)}}/>

      <Category handleCategory={handleCategory} category={+product.id_category}/>

      <div className={classes.images_div}>
        <div className={classes.img_preview}>
          {imgupload.src && <div className={classes.img_preview}>
              <p>Imaginea principala</p>
              <img src={imgupload.src}></img>
              <button onClick={() => setImgupload({name: null, file: null, src: null})}>Sterge</button>
            </div>}

          {deleteImg ? 
          
            <Button className={classes.btn_images} variant="contained" component="label">
              <MdAddCircleOutline/> <p> Adauga imagine principala</p>
              <input hidden accept="image/*" multiple type="file" onChange={onChangeImg}/>
            </Button>
            :
            <div className={classes.img_preview}>
               <p>Imaginea principala</p>
              <img src={product.image_path_small} alt={product.title}></img>
              <button onClick={() => {setIDeleteImg(true); handleTempImage()}}>Sterge</button> 
          </div>
          }

        </div>

        <div style={{display: 'flex', gap: '2px', flexDirection: 'column', justifyContent: "center", alignItems: 'center'}}>
            <div style={{display: 'flex', gap: '2px', padding: "2px", borderRadius: "2px"}}>
              {images && images.map((img, index) => (
                <div key={index} className={classes.img_preview}>
                  <img src={img.image_path_small} alt="IMG for product"></img>
                  <button onClick={() => deleteImage(+img.id, img.image)}>Sterge</button>
                </div>
              ))}
                            {imgsupload.map((img, index) => (
              <div key={index} className={classes.img_preview}>
                <img src={img.src} alt={img.name}></img>
                <button onClick={() => deletePreviewImg(index)}>Sterge</button>
              </div>
              ))}
            </div>

            <Button className={classes.btn_images} variant="contained" component="label">
                <MdAddCircleOutline/> <p>Adauga imagine</p>
                <input hidden accept="image/*" multiple type="file" onChange={onChangeImgs}/>
            </Button>
        </div>
      </div>

        <BundledEditor
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={description}
          init={{
            height: 500,
            menubar: false,
            plugins: [
              'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
              'searchreplace', 'table', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
              'bold italic forecolor | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          }}
          onChange={log}
        />


        <Button variant="contained" color="success" size="large" onClick={() => {uploadHandler(); uploadImages(); updateImages(); updateProduct()}}>
            Update produs
        </Button>
    </FormLabel>
        }
  </>
  )
}

export default Edit