
import React from 'react';
import './App.css';
import Home from './components/Home/Home';
import { Routes, Route } from 'react-router-dom';
import PageProduct from './components/PageProduct/PageProduct';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Cart from './components/Cart/Cart';
import Admin from './components/Admin/Admin';
import DataProvider from './components/DataProvider';
import Terms from './components/Terms/Terms';
import Delivery from './components/Terms/Delivery';
import Returns from './components/Terms/Returns';
import Category from './components/Category/Category';
import Success from './components/Cart/Success';

function App() {

  return (
    <div className="App">
      <DataProvider>
        <Routes basename={process.env.PUBLIC_URL}>
          <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
          <Route path={`${process.env.PUBLIC_URL}/:category`} element={<Category />} />
          <Route path={`${process.env.PUBLIC_URL}/:category/:productName/`} element={<PageProduct />} />
          <Route path={`${process.env.PUBLIC_URL}/despre`} element={<About />} />
          <Route path={`${process.env.PUBLIC_URL}/contact`} element={<Contact />} />
          <Route path={`${process.env.PUBLIC_URL}/cart`} element={<Cart />} />
          <Route path={`${process.env.PUBLIC_URL}/admin`} element={<Admin />} />
          <Route path={`${process.env.PUBLIC_URL}/success/:id`} element={<Success />} />
          <Route path={`${process.env.PUBLIC_URL}/termeni-si-conditii`} element={<Terms />} />
          <Route path={`${process.env.PUBLIC_URL}/informatii-livrare`} element={<Delivery />} />
          <Route path={`${process.env.PUBLIC_URL}/politica-retur`} element={<Returns />} />
        </Routes>
      </DataProvider>
    </div>
  );
}

export default App;
