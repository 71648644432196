import React from 'react';
import BundledEditor from '../../../BundledEditor';

const Description = ({ log, editorRef, description }) => {

  return (
    <BundledEditor
      onInit={(evt, editor) => editorRef.current = editor}
      initialValue= {description}
      init={{
        height: 350,
        menubar: false,
        plugins: [
          'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
          'searchreplace', 'table', 'wordcount'
        ],
        toolbar: 'undo redo | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
      onChange={log}
    />
  )
}

export default Description