import React, { useState, useEffect } from 'react';
import classes from './Products.module.css';
import Axios from 'axios';
import { Button } from '@mui/material';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { cartActions } from '../../../store/cart';
import PaginationItems from '../../PaginationItems/PaginationItems';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import proxy from '../../../proxy';

// ICONS
import { MdAddCircleOutline } from 'react-icons/md';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


const Products = ({ clickHandler, handleTitle }) => {

    const [products, setProducts] = useState([]);

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState();
    const [filter, setFilter] = useState("");
    const [state, setState] = useState({
      totalProducts: null,
      productsInactive: null
    });

    const handleChangePage = (event, value) => {
      setPage(value);
    };

    const dispatch = useDispatch();

    const editId = (product) => {
      dispatch(cartActions.productEditID({
        product
      }))
    };

    const handleActiveProduct = (id, active) => {
      Axios.post(`${proxy}/server/edit/setActiveProduct.php`, {id: id, active: active}).then((response) => {
        getProducts();
      })
    }


    const deleteProduct = (product) => {
                Swal.fire({
                  title: 'Esti sigur ca vrei sa stergi acest produs?',
                  showDenyButton: true,
                  confirmButtonText: 'Sterge',
                  denyButtonText: 'Renunta',
                  icon: 'warning',
                  denyButtonColor: '#3085d6',
                  confirmButtonColor: '#ff0000',
                  cancelButtonColor: '#3085d6',
                })
                .then((result) => {

                  if (result.isConfirmed) {

                    Axios.delete(`${proxy}/server/delete/deleteProduct.php`, { data: {id: product.id, filename: product.image_path} }).then((response) => {

                      if(response.data.status !== 0) {
                        Swal.fire('Produsul a fost sters!', '', 'success').then(() => {
                          getProducts();
                        })
                      } else {
                        Swal.fire("Produsul este asociat unei comenzi si nu poate fi sters", "", 'error');
                      }
                    })
                  } else if (result.isDenied) {
                    Swal.fire('Produsul nu a fost sters!', '', 'info')
                  }
                });
            };


  const getProducts = () => {
    Axios.get(`${proxy}/server/getProductsAdmin.php`, {params: {page, filter}}).then((response) => {

      setProducts(response.data.products)
      setPages(response.data.pages);
      setState({totalProducts: response.data.totalProducts, productsInactive: response.data.productsInactive})
    })
  }

    useEffect(() => {
      getProducts();
    }, [page, filter]);

  return (
    <div className={classes.page_products}>

      <div className={classes.btn_add}>
        <div className={classes.info}>
          <p>Total Produse: {state?.totalProducts} &#8660; Inactive: {state?.productsInactive}</p>
        </div>
        <FormControl>
      <RadioGroup
        defaultValue=""
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel value="" onChange={(e) => {setFilter(e.target.value); setPage(1)}} control={<Radio />} label="Toate produsele" />
        <FormControlLabel value="product.active = 1 AND category.active = 1" onChange={(e) => {setFilter(e.target.value); setPage(1)}} control={<Radio />} label="Active" />
        <FormControlLabel value="product.active = 0 OR category.active = 0 " onChange={(e) => {setFilter(e.target.value); setPage(1)}} control={<Radio />} label="Inactive" />
      </RadioGroup>
    </FormControl>
        <Button className={classes.btn} variant="contained" size="large" onClick={() => {clickHandler("viewAdd"); handleTitle("Adauga produs")}}><MdAddCircleOutline  />Adauga Produs Nou</Button>
      </div>
      
      <div className={classes.admin_products}>
        <div className={classes.admin_products_head}>
          <p>Produs</p>
          <p>SKU</p>
          <p>Pret</p>
          <p>Categorie</p>
          <p style={{textAlign: 'center'}}>Comandat</p>
        </div>
          {
              products.map((product) => (
                  <div className={+product.active && +product.catActive ? classes.admin_product : `${classes.admin_product} ${classes.inactive}`} key={product.id} >
                    <div style={{display: "flex", alignItems: "center", gap: ".5rem"}}>
                      <img style={{borderRadius: "5px"}} className={classes.image} src={product.image_path_icon} alt={product.title}></img>
                      <p className={classes.title}>{product.title}</p>
                    </div>
                    <div><p>{product.sku}</p></div>
                    <div><p className={classes.price}>{product.price} RON</p></div>
                    <div><p className={classes.title}>{+product.catActive ? product.category : <del>{product.category}</del>}</p></div>
                    <div style={{textAlign: 'center'}}><p className={classes.title}>{+product.OrderedBy ? (+product.OrderedBy > 1 ? `de ${product.OrderedBy} ori` : "o data") : "-"}</p></div>
                    
                    <div className={classes.product_btn}>

                       <Button variant="contained" color="success" sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2px", padding: "10px 15px", minWidth: "0"}}
                        disabled={+product.catActive === 0}
                        onClick={() => handleActiveProduct(product.id, (+product.active ? 0 : 1))}>
                        {+product.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </Button> 

                      <Button variant="contained" color="primary" sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2px", padding: "10px 15px", minWidth: "0"}}
                        disabled={+product.catActive === 0}
                        onClick={() => {clickHandler("viewEdit"); editId(product); handleTitle("Editare Produs")}}>
                        <EditIcon  />
                      </Button>   

                      <Button onClick={() => deleteProduct(product)} className={classes.delete} 
                        color="error" variant="contained"
                        disabled={+product.catActive === 0}
                        sx={{display: "flex", justifyContent: "center", alignItems: "center", gap: "2px", padding: "10px 15px", minWidth: "0"}}>
                        <DeleteIcon  />
                      </Button>                 
                    </div>
                  </div>
              ))
          }
      </div>

      <PaginationItems pages={pages} handleChangePage={handleChangePage} page={page}/>

    </div>
  )
}

export default Products