import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

const FilterOrders = ({ setIdComanda, setName, setEmail, setCity, setDate, date, idComanda, name, city, email, handleReset, setPaid, setMtd, paid, mtd }) => {

  const handlePaid = (event) => {
    setPaid(event.target.value);
  };

  const handleMethod = (event) => {
    setMtd(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 }, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#FFFDFA', borderRadius: ".5rem", padding: '1rem'
      }}
      noValidate
      autoComplete="off"
    >
        <TextField size="small" id="outlined-basic" label="ID Comanda" variant="outlined" autoComplete="off" value={idComanda} onChange={(e) => {setIdComanda(e.target.value)}}/>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Data comanda"
            inputFormat="DD/MM/YYYY"
            value={date}
            onChange={(newValue) => {
                setDate(`${newValue.$y}-${newValue.$M + 1}-${newValue.$D}`);
            }}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>

        <TextField size="small" id="outlined-basic" label="Nume & Prenume" variant="outlined" autoComplete="off" value={name} onChange={(e) => {setName(e.target.value)}}/>
        <TextField size="small" id="outlined-basic" label="Oras" variant="outlined" autoComplete="off" value={city} onChange={(e) => {setCity(e.target.value)}}/>
        <TextField size="small" id="outlined-basic" label="Email" variant="outlined" autoComplete="off" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handlePaid}
          >
            <FormControlLabel value="platita" control={<Radio checked={paid === "platita"} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label="Platita" />
            <FormControlLabel value="neplatita" control={<Radio checked={paid === "neplatita"} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label="Neplatita" />
        </RadioGroup>

        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleMethod}
          >
            <FormControlLabel value="card" control={<Radio checked={mtd === "card"} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label="Card" />
            <FormControlLabel value="cash" control={<Radio checked={mtd === "cash"} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }} />} label="Cash" />
        </RadioGroup>

              <Button variant="contained" onClick={() => {handleReset()}}>Reseteaza</Button>
    </Box>
  )
}

export default FilterOrders